import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["gray-200"]};
	height: 100%;
	overflow: auto;
	padding-top: 8px;
`

export const FooterItem = styled.div`
  align-items: center;
	display: flex;
  flex: 1;
	gap: 15px;
  justify-content: space-between;
  padding: 4px 6px;

	span {
		color: ${defaultTheme["gray-900"]};
	}
`

export const BoldText = styled.p`
	font-style: italic;
	font-weight: bold;
`
