/* eslint-disable react/display-name */
import { BottomTabBarProps, createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { NavigationContainer } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import React, { useEffect, useState, useMemo } from "react"
import { ActivityIndicator, View } from "react-native"
import Navigation from "../models/navigation"
import "react-native-gesture-handler"
import { defaultTheme } from "../styles/themes/default"
import Header from "../views/components/Header/index.web"
import CalendarScreen from "../views/screens/CalendarScreen"
import DashboardsBuilderScreen from "../views/screens/DashboardsBuilderScreen"
import DashBoardScreen from "../views/screens/DashBoardScreen"
import HomeScreen from "../views/screens/HomeScreen"
import LoginScreen from "../views/screens/LoginScreen"
import OrderComposerScreen from "../views/screens/OrderComposerScreen"
import OrderPanelScreen from "../views/screens/OrderPanelScreen"
import RegistrationScreen from "../views/screens/Registration"
import ReportScreen from "../views/screens/ReportScreen"
import ScreenBuilderScreen from "../views/screens/ScreenBuilderScreen"

const Stack = createNativeStackNavigator()
const Tab = createBottomTabNavigator()

function Root() {
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    Navigation.setTabsFunction(setTabs)
  }, [])

  const getComponent = useMemo(() => (component: string) => {
    const components = {
      "Calendar": CalendarScreen,
      "Dashboard": DashBoardScreen,
      "DashboardsBuilder": DashboardsBuilderScreen,
      "Hierarchic": RegistrationScreen,
      "OrderComposer": OrderComposerStack,
      "OrderPanel": OrderPanelScreen,
      "Registration": RegistrationScreen,
      "ScreenBuilder": ScreenBuilderScreen,
      "Reports": ReportScreen
    }
    return components[component]
  }, [])

  const renderTab = useMemo(
    () => ({ tabName, component, params }) => (
      <Tab.Screen
        key={tabName}
        name={tabName}
        component={getComponent(component)}
        initialParams={params}
        options={{ lazy: true }}
      />
    ),
    [getComponent]
  )

  const renderHeader = (headerProps: BottomTabBarProps) => <Header {...headerProps} />

  return (
    <Tab.Navigator tabBar={renderHeader}>
      <Tab.Screen name="Overview" component={HomeScreen} />
      {tabs.map(renderTab)}
    </Tab.Navigator>
  )
}

function OrderComposerStack({ route, navigation }) {
  const [controller] = useState(route.params.controller)
  const [isLoading, setIsLoading] = useState(true)

  const [screens, setScreens] = useState(() => {
    if (route.params.initialScreen) {
      return [{
        name: route.params.initialScreen.name,
        params: route.params.initialScreen
      }]
    } else return []
  })

  useEffect(() => {
    controller.setScreens = setScreens
    controller.navigation = navigation

    const fetchData = async () => {
      setIsLoading(true)
      if (route.params.initialScreen) {
        await controller.getNavigationFields(route.params.initialScreen.transactionId, true)
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      controller.setScreens = null
      controller.navigation = null
    }
  }, [controller, navigation, route.params.initialScreen])

  if (isLoading) {
    return (
      <View style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}>
        <ActivityIndicator size={"large"} color={defaultTheme["cyan-700"]} />
      </View>
    )
  } else {
    return (
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {screens.map(({ name, params }) => (
          <Stack.Screen key={name} name={name} component={OrderComposerScreen} initialParams={params} />
        ))}
        <Stack.Screen name="Transaction" component={OrderComposerScreen} initialParams={controller.orderPrototype.T_SET_TNS} options={{ presentation: "modal" }} />
      </Stack.Navigator>
    )
  }
}

export default function Routes() {
  return (
    <NavigationContainer documentTitle={{ formatter: () => "Musa" }}>
      <Stack.Navigator>
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
        <Stack.Screen name="Root" component={Root} options={{ headerShown: false }} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}
