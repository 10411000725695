import React from "react"
import { useTranslation } from "react-i18next"
import idValidation from "../../utils/idValidation"
import AccessibilityBox from "../AccessibilityBox"
import useInputManager from "../hooks/useInputManager"
import * as S from "./styles.web"

export default function Integer({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  idValidation(field, models)

  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT, SIZE } = field.T_DTD_ATR

  const { state, setState, onBlur } = useInputManager({
    models,
    field,
    updateForm,
    setUpdateForm
  })

  const { width, height } = sizeConvert(SIZE?.VALUE)

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>

        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.Input
        placeholder={t(FIELDDESCRIPTION)}
        isEditable={EDITABLE}
        disabled={!EDITABLE}
        width={width}
        height={height}
        value={state ?? ""}
        onChange={e => setState(e.target.value)}
        onBlur={onBlur}
      />
    </S.Container>
  )
}

function sizeConvert(size: string) {
  switch (size) {
    case "M": return {
      width: 350,
      height: 200
    }
    case "L": return {
      width: 800,
      height: 320
    }
    default: return {
      width: 200,
      height: 150
    }
  }
}