import { FontAwesome5, Entypo } from "@expo/vector-icons"
import styled from "rn-css"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"

export const Container = styled.View`
  background-color: ${defaultTheme["white"]};
  padding: 8px;
  cursor: grab;
  border-radius: 4;
  box-shadow: 0px 4px 8px ${defaultTheme["shadow"]};
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.Text`
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  font-weight: 500;
`

export const ButtonsView = styled.View`
  width: 60%;
  gap: 35px;
  position: absolute;
  right: 55px;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

interface BoxProps {
  isActive: boolean
}

export const Box = styled.TouchableOpacity<BoxProps>`
  background-color: ${({ isActive }) =>
    isActive ? defaultTheme["cyan-500"] : "transparent"};
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${defaultTheme["gray-700"]};
  border-width: ${({ isActive }) => (isActive ? 0 : 1)}px;
  border-radius: 2px;
`

export const Icon = styled(Entypo)<BoxProps>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  color: ${defaultTheme["gray-100"]};
  font-size: 12px;
`

export const Button = styled.TouchableOpacity``

export const DeleteIcon = styled(FontAwesome5)`
  color: ${defaultTheme["red-400"]};
  font-size: 14px;
`
export const EditIcon = styled(FontAwesome5)`
  font-size: 12px;
`

export const ContainerDiv = styled.View`
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
`
export const TextDiv = styled.View`
  width: 150px;
`
