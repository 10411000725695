import React, { createContext, useState } from "react"

const FiltersContext = createContext()

const FiltersProvider = ({ children }) => {
  const [fields, setFields] = useState([])
  const [filters, setFilters] = useState([])

  return (
    <FiltersContext.Provider value={{
      fields,
      setFields,
      filters,
      setFilters
    }}>
      {children}
    </FiltersContext.Provider>
  )
}

export { FiltersContext, FiltersProvider }
