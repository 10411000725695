import styled from "styled-components"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  background-color: ${defaultTheme["gray-200"]};
  width: 100%;
  padding: 8px 20px;
  justify-content: space-between;
  align-items: center;
`

export const ItemsContainer = styled.div`
  display: flex;
  gap: 15px;
  height: 100%;
  align-items: center;
`

export const ClearFilteringButton = styled.button`
  text-align: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 10px;
  height: 32px;
  padding: 0 10px;
  color: ${defaultTheme["gray-600"]};
  font-size: 0.875rem;
`
