import showAlert from "../../../../../../services/showAlert"

const handleApplyFilters = ({ controller, setApplyFilters, setUpdate, searchText }) => {

  console.log("handleApplyFilters")

  if ((!controller.searchFilters?.length || !controller?.searchFilters) && searchText !== "")
    return showAlert({
      text: "Select at least one field to search",
      title: "No filter selected",
      titleType: "warning"
    })
  setApplyFilters(searchText ? true : false)
  setUpdate(s => !s)
}

export default handleApplyFilters
