import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  color: ${defaultTheme["gray-900"]};
  display: flex;
  justify-content: space-between;

  span:first-child {
    font-style: italic;
    font-weight: bold;
  }
`
