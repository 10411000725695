import React from "react"
import { useTranslation } from "react-i18next"
import idValidation from "../../utils/idValidation"
import AccessibilityBox from "../AccessibilityBox"
import * as S from "./styles.web"
import useDatetime from "./hooks/useDatetime"

export default function Date({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  //idValidation(field, models)

  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT } = field.T_DTD_ATR

  const { state, setState, onBlur } = useDatetime({ models, field, updateForm, setUpdateForm })

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>

        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.Input
        type="datetime-local"
        placeholder={t(FIELDDESCRIPTION)}
        isEditable={EDITABLE}
        disabled={!EDITABLE}
        value={state}
        onChange={e => setState(e.target.value)}
        onBlur={onBlur}
      />
    </S.Container>
  )
}
