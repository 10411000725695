import React from "react"
import * as S from "./styles"

export default function Attachment(props: any) {
  const hasAttachment = Boolean(props[props.fieldData?.ID])
  const isIMGField = props.fieldData?.ID === "IMG"

  return (
    <S.Container>
      {isIMGField ? (
        <S.ImageContainer hasAttachment={hasAttachment}>
          {hasAttachment ? <S.Image src={props[props.fieldData?.ID]} /> : <S.ImageIcon />}
        </S.ImageContainer>
      ) : (
        <S.ClipIcon hasAttachment={hasAttachment} />
      )}
    </S.Container>
  )
}
