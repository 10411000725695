import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"
import ItemTypes from "../../../ItemTypes"
import * as S from "./styles"

export default function Card({
  filterData,
  index,
  moveCard,
  setVisibleFields,
  setNonVisibleFields,
  isHeaderConfiguration,
  setModalVisible,
  setSelectedField
}) {
  const { t } = useTranslation()

  const ref = useRef(null)

  const { id, description, required, editable } = filterData

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === undefined || hoverIndex === undefined) return
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({
      id,
      index
    }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() })
  })

  const cardOpacity = isDragging ? 0 : 1

  drag(drop(ref))

  const handlePress = (attribute) => {
    setVisibleFields((state) => {
      const newState = [...state]
      if (attribute === "type")
        newState[index][attribute] =
          newState[index][attribute] === "N" ? "K" : "N"
      else newState[index][attribute] = !newState[index][attribute]

      return newState
    })
  }

  const removeVisibleField = () => {
    setVisibleFields((state) => {
      return state.filter((filter) => filter.id !== id)
    })
  }

  const addNonVisibleField = () => {
    setNonVisibleFields((state) => {
      const newState = [
        ...state,
        {
          ...filterData,
          visible: false
        }
      ]

      newState.sort((a, b) => t(a.description).localeCompare(t(b.description)))
      return newState
    })
  }

  const isPrimaryKey = filterData.id === "TNSID"
  const isSearchField = ["K", "N"].includes(filterData.type)

  const editIconColor =
    filterData.expression === undefined ||
    filterData.expression === "" ||
    filterData.expression === null
      ? defaultTheme["gray-400"]
      : defaultTheme["orange-500"]

  return (
    <S.Container
      ref={ref}
      style={{ opacity: cardOpacity }}
      data-handler-id={handlerId}
    >
      <S.ContainerDiv>
        {!isPrimaryKey && isSearchField && isHeaderConfiguration && (
          <S.Button
            onPress={() => {
              setSelectedField(filterData)

              setModalVisible(true)
            }}
          >
            <S.EditIcon name="pencil-alt" color={editIconColor} />
          </S.Button>
        )}
        <S.TextDiv>
          <S.Text numberOfLines={1} ellipsizeMode="tail">
            {t(description)}
          </S.Text>
        </S.TextDiv>
      </S.ContainerDiv>

      <S.ButtonsView>
        {!isPrimaryKey && isSearchField && isHeaderConfiguration && (
          <S.Box
            isActive={filterData.type === "N"}
            onPress={() => handlePress("type")}
          >
            <S.Icon name="check" isActive={filterData.type === "N"} />
          </S.Box>
        )}

        <S.Box isActive={editable} onPress={() => handlePress("editable")}>
          <S.Icon name="check" isActive={editable} />
        </S.Box>

        <S.Box isActive={required} onPress={() => handlePress("required")}>
          <S.Icon name="check" isActive={required} />
        </S.Box>
      </S.ButtonsView>
      <S.Button
        onPress={() => {
          removeVisibleField()
          addNonVisibleField()
        }}
      >
        <S.DeleteIcon name="trash" />
      </S.Button>
    </S.Container>
  )
}
