import { FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.View`
  height: 100%;
  gap: 10px;
  width: 200px;
`

export const Title = styled.Text`
  font-size: 14px;
  font-weight: 500;
  color: ${defaultTheme["gray-900"]};
`

export const SearchContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SearchInput = styled.TextInput`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-900"]};
  width: 200px;
  padding: 0 4px;
  border-bottom: 1px solid ${defaultTheme["gray-400"]};
  outline-style: none;
  font-size: 14px;
`

export const Button = styled.TouchableOpacity``

export const Icon = styled(FontAwesome)`
  color: ${defaultTheme["gray-900"]};
  font-size: 16px;
`

export const LoadingContainer = styled.View`
  flex: 1;
  justify-content: center;
`

export const Loading = styled.ActivityIndicator.attrs({
  color: defaultTheme["cyan-500"],
  size: "large"
})``

export const List = styled.ScrollView``
