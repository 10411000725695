import { MaterialCommunityIcons, Feather } from "@expo/vector-icons"
import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import getTableFields from "../../../../../../../services/registration/getTableFields"
import showAlert from "../../../../../../../services/showAlert"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import * as S from "./styles"

export default function DropdownFilter({
  screenProps,
  controller,
  isSearchInputDisable
}) {

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [itemsSelected, setItemsSelected] = useState([])
  const dropdownRef = useRef(null)

  const toggleDropdown = () => setIsOpen(s => !s)

  useEffect(() => {
    loadData()

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const loadData = async () => {
    const fields = await getTableFields({ entityName: screenProps.collectionName })

    const defaultFilters = []
    const formattedFields = fields.filter(field => field.FIELDTYPE.VALUE === "S")
      .map(field => {

        if (field.T_DTD_ATR?.CARDFILTER) defaultFilters.push(field.ID)

        return {
          fieldId: field.ID,
          fieldDescription: field.T_DTD_ATR.FIELDDESCRIPTION
        }
      })

    formattedFields.forEach(field => {
      handleSelectField(field.fieldId)
    })
    setData(formattedFields)
  }

  const handleSelectField = (item) => {
    if (itemsSelected.length === 5) return showAlert({
      title: "Filter limit",
      text: "Limit of 5 filters reached",
      titleType: "info"
    })
    let aux = []
    const isExists = itemsSelected.includes(item)

    if (isExists) aux = itemsSelected.filter(i => i !== item)
    else aux = [...itemsSelected, item]

    setItemsSelected(aux)
    controller.searchFilters = aux
  }

  const renderListItem = (item) => {
    const isSelected = itemsSelected.includes(item.fieldId)

    return (
      <S.DropdownListItem
        key={item.fieldId}
        onClick={() => handleSelectField(item.fieldId)}
      >
        <S.Checkbox isSelected={isSelected}>
          <Feather name="check" size={12} color={isSelected ? defaultTheme["white"] : defaultTheme["gray-700"]} />
        </S.Checkbox>
        <span>{t(item.fieldDescription)}</span>
      </S.DropdownListItem>
    )

  }

  return (
    <S.Container>

      <S.FiltersCounter>{itemsSelected.length}</S.FiltersCounter>
      <S.FilterButton
        onClick={toggleDropdown}
        disabled={isSearchInputDisable}
        isDisabledSearchInput={isSearchInputDisable}
        ref={dropdownRef}
      >
        <MaterialCommunityIcons name="filter-plus-outline" size={18} color={defaultTheme["gray-700"]} />
      </S.FilterButton>

      <S.DropdownList isOpen={isOpen} ref={dropdownRef}>

        <S.DropdownTitle>{t("Search by:")}</S.DropdownTitle>
        <S.DropdownListItemsContainer>
          {data.map(renderListItem)}
        </S.DropdownListItemsContainer>
      </S.DropdownList>
    </S.Container>
  )
}

