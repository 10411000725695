import styled from "styled-components"
import AntDesign from '@expo/vector-icons/AntDesign'
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3px 10px;
  border: 1px solid ${defaultTheme["gray-500"]};
  border-radius: 10px;
  width: 300px;
  height: 32px;
  background-color: ${defaultTheme["gray-100"]};
`

export const Input = styled.input`
  background-color: transparent;
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  width: 100%;
  border: none;
  height: 100%;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const Button = styled.button`
  background-color: transparent;
`

export const Icon = styled(AntDesign)
  .attrs(({
    color: defaultTheme["gray-500"],
    size: 20
  }))``