import dbRequest from "../../../../services/dbRequest/dbRequest"
import detailTabHasRecords from "../../../../services/registration/detailTabHasRecords"
import foreignKeyValidation from "../../../../services/registration/foreignKeyValidation"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"
import hierarchicalRecordValidation from "../../ScreenFactory/utils/ButtonsFunctions/utils/delete/hierarchicalRecordValidation"

const handleDelete = ({ gridRef, models, entity, mode, toggleMode, changeLoadingModalVisibility }) => {
  const tableData = models._REGISTRATION_DATA[entity]

  if (mode === "form") {
    return tableData.operation === "INS"
      ? showAlert({
        text: "No record selected",
        title: "Failed to delete",
        titleType: "error"
      })
      : showAlert({
        text: "Do you want to delete the current record?",
        type: "confirm",
        titleType: "question",
        handleConfirm: async () => {
          await deleteRecord({
            models,
            entity,
            records: [{ ...models[entity] }],
            changeLoadingModalVisibility
          })
          toggleMode()
        }
      })
  }

  const selectedRecords = gridRef.current.getSelectedRecords()

  if (selectedRecords.length === 0)
    return showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "error"
    })

  showAlert({
    text: "Do you want to delete the selected record(s)?",
    type: "confirm",
    titleType: "question",
    handleConfirm: async () => {
      await deleteRecord({
        models,
        entity,
        records: selectedRecords,
        changeLoadingModalVisibility
      }).then((isDeletedRecords) => {
        isDeletedRecords && gridRef.current.deleteRecord()
      })
    }
  })
}

export default handleDelete

async function deleteRecord({ models, entity, records, changeLoadingModalVisibility }) {
  if (entity === "T_SET_RLU") //Migrate to user screen
    showAlert({
      text: "A user cannot be deleted, only deactivated",
      title: "Failed to delete",
      titleType: "error"
    })

  changeLoadingModalVisibility()

  const tableData = models._REGISTRATION_DATA[entity]
  const failedRecords = []
  let successCount = 0

  tableData.operation = "DEL"

  for (const record of records) {
    tableData.state = "BEFORE"
    models[entity].reset()
    models[entity].populate(record)

    const isForeignKey = await foreignKeyValidation({
      entity,
      record
    })
    if (isForeignKey) {
      failedRecords.push({
        record,
        reason: "Foreign key constraint"
      })
      continue
    }

    if (tableData.type === "Hierarchic") {
      const hasChildRecords = await hierarchicalRecordValidation(entity, record)
      if (hasChildRecords) {
        failedRecords.push({
          record,
          reason: "Record has child records"
        })
        continue
      }
    }

    const allTriggersValid = await runTriggers(models, entity)
    if (!allTriggersValid) {
      failedRecords.push({
        record,
        reason: "Triggers failed"
      })
      continue
    }

    const hasDetailRecords = await detailTabHasRecords({
      entity,
      models
    })
    if (hasDetailRecords) {
      failedRecords.push({
        record,
        reason: "Detail records found"
      })
      continue
    }

    await dbRequest
      .deleteRecords(entity)
      .record(record)
      .execute()

    successCount++
    tableData.state = "AFTER"
    await runTriggers(models, entity)
  }

  tableData.currentRecordIndex = null

  changeLoadingModalVisibility()

  const hasFailedRecords = failedRecords.length > 0

  if (hasFailedRecords) {
    const failureMessages = failedRecords
      .map(({ record, reason }) => `ID: ${record.ID} - Reason: ${reason}`)
      .join("\n")

    showAlert({
      text: failureMessages,
      title: `Failed to delete ${failedRecords.length} record(s)`,
      titleType: "warning"
    })

    return false
  } else {
    showAlert({
      text: "All selected records have been deleted",
      title: "Record deletion",
      titleType: "success"
    })

    return true
  }
}
