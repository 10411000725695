import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import showAlert from "../../../../services/showAlert"
import ActionButton from "../components/ActionButton"
import ColumnsManager from "../components/ColumnsManager"
import CustomSettings from "../components/CustomSettings"
import SearchInput from "../components/Inputs/Search"
import LoadingModal from "../components/LoadingModal"
import MoreOptionsButton from "../components/MoreOptionsButton"
import { toolbarIcons } from "../utils/icons"
import * as S from "./styles"

export default function GridToolbar({ entity, models, functions, gridRef }) {
  const { t } = useTranslation()

  const [loadingModalVisible, setLoadingModalVisible] = useState(false)

  const { handleInsert, handleCopy, handleDelete, handleRefresh } = functions

  const { permissions, type } = models._REGISTRATION_DATA[entity]

  const isHierarchicalScreen = type === "Hierarchic"

  const { CREATE, DELETE } = permissions

  const handleCleanFilters = () => {
    showAlert({
      type: "confirm",
      text: "Do you want to clean filters?",
      title: "Clean filters",
      titleType: "question",
      handleConfirm: () => gridRef.current.clearFiltering()
    })
  }

  return (
    <S.Container>
      <S.ItemsContainer>
        {handleInsert && <ActionButton
          action={handleInsert}
          disabled={!CREATE}
          text={t("Add new record")}
          renderText
          icon={toolbarIcons.add}
        />}

        {handleCopy && <ActionButton
          action={handleCopy}
          disabled={!CREATE}
          text={t("Copy record")}
          icon={toolbarIcons.copy}
        />}

        {handleDelete && <ActionButton
          action={() => handleDelete({ changeLoadingModalVisibility: () => setLoadingModalVisible(s => !s) })}
          disabled={!DELETE}
          text={t("Delete record")}
          icon={toolbarIcons.delete}
        />}

        {handleRefresh && <ActionButton
          action={handleRefresh}
          text={t("Refresh records")}
          icon={toolbarIcons.refresh}
        />}

        <SearchInput gridRef={gridRef} />
      </S.ItemsContainer>

      <S.ItemsContainer>
        {isHierarchicalScreen && <ActionButton
          action={() => gridRef.current.expandAll()}
          text={t("Expand all")}
          icon={toolbarIcons.expand}
          renderText
        />}

        {isHierarchicalScreen && <ActionButton
          action={() => gridRef.current.collapseAll()}
          text={t("Collapse all")}
          icon={toolbarIcons.collapse}
          renderText
        />}

        <CustomSettings entity={entity} gridRef={gridRef} handleRefresh={handleRefresh} models={models} />

        <S.ClearFilteringButton onClick={handleCleanFilters}>{t("Clean filters")}</S.ClearFilteringButton>

        <ColumnsManager entity={entity} gridRef={gridRef} />

        <MoreOptionsButton entity={entity} models={models} gridRef={gridRef} setUpdateForm={() => null} />
      </S.ItemsContainer>

      <LoadingModal isVisible={loadingModalVisible} />
    </S.Container>
  )
}
