import React from "react"
import * as S from "../../HourTemplate/styles"

export default function Hour(props: any) {
  const value = props[props.fieldData?.ID]

  return (
    <S.Container>
      {value ? minutesToTime(value) : ""}
    </S.Container>
  )
}

function minutesToTime(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(2, "0")}`
}
