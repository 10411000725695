import { Feather } from "@expo/vector-icons"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import hasPermissionToUpdate from "../../../../../services/hasPermissionToUpdate"
import { defaultTheme } from "../../../../../styles/themes/default"
import getSideBarFields from "../utils/getSideBarFields"
import * as S from "./styles.web"

const Summary = ({ models, jumpTo, controller }) => {

  const { t } = useTranslation()

  const [navigationFields, setNavigationFields] = useState([])

  const fieldTitle = (field) => field.T_DTD_ATR.FIELDDESCRIPTION
  const fieldID = (field) => models.T_MOV_HDR[field.ID]?.ID ?? 0
  const fieldDescription = (field) => models.T_MOV_HDR[field.ID]?.DESCRIPTION

  const showEditButton = (field) => {
    if (field.ID === "TNSID") return false
    const canUpdate = hasPermissionToUpdate(models)
    return canUpdate
  }

  useEffect(() => {
    (async () => {
      const fields = await getSideBarFields(controller)
      if (fields.length) setNavigationFields(fields)
    })()
  }, [models.T_SET_TNS?.ID])

  return (
    <S.Container>
      <S.Title>{t("Summary")}</S.Title>

      <S.Content>
        {navigationFields.map(field => {
          return (
            <S.FieldView key={field.ID} style={{ display: fieldDescription(field) === undefined ? "none" : "flex" }}>
              <S.Key>{t(fieldTitle(field))}</S.Key>
              <S.Value>{t(fieldID(field))} - {t(fieldDescription(field))}
                {showEditButton(field) && <S.EditButton onClick={() => jumpTo(field.T_DTD_ATR.FIELDDESCRIPTION)}>
                  <Feather name="edit" size={16} color={defaultTheme["gray-700"]} />
                </S.EditButton>}
              </S.Value>
            </S.FieldView>
          )
        })}
      </S.Content>
    </S.Container>
  )
}

export default Summary
