import { AntDesign } from "@expo/vector-icons"
import styled from "rn-css"
import normalize from "../../../../../styles/normalizeSize"
import { defaultTheme, fontFamily } from "../../../../../styles/themes/default"

export const Container = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: ${normalize(10)}px;
  padding: ${normalize(6)}px ${normalize(8)}px;
  border-radius: 2px;
  background-color: ${defaultTheme["white"]};
`

export const Icon = styled(AntDesign)`
  height: 100%;
  font-size: ${normalize(18)}px;
  font-family: ${fontFamily["medium"]};
  color: ${defaultTheme["cyan-700"]};
  `

export const Title = styled.Text`
height: 100%;
font-size: ${normalize(14)}px;

font-family: ${fontFamily["medium"]};
  color: ${defaultTheme["cyan-700"]};
`
