import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"
import { Platform } from "react-native"
import styled from "styled-components"
import globalStyles from "../../../global/globalStyles"
import { SelectionInput } from "../defaultInputsStyles"

export const Container = styled.button`
  width: 49px;
  height: 40px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme["cyan-800"]};

  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme["cyan-300"]};
    box-shadow: 3px 0px 5px #00000029;
  }

`

/* export const Icon = styled(FontAwesome)`
  color: ${Platform.OS === "web" ? globalStyles.colors.tertiary : globalStyles.colors.secondary};
  font-size: ${Platform.OS === "web" ? 25 : 35}px;
` */

/* export const ModalContainer = styled.View`
  background-color: ${globalStyles.colors.primary};

  width: ${Platform.OS === "web" ? 25 : 90}%;
  height: 25%;

  justify-content: center;
  align-items: center;
  align-self: center;

  border-radius: 4px;

  gap: 20px;
`

export const CloseButton = styled.TouchableOpacity`
  position: absolute;
  top: 5px;
  right: 10px;
`

export const CloseIcon = styled(MaterialCommunityIcons)`
  color: ${globalStyles.colors.tertiary};
  font-size: ${Platform.OS === "web" ? 20 : 35}px;
`

export const Title = styled.Text`
  font-size: 18px;
  font-weight: bold;
  color: ${globalStyles.colors.tertiary};
`

export const Input = styled(SelectionInput).attrs(() => {

  return {
    // Label and Value
    labelField: "label",
    valueField: "value",

    // Dropdown
    dropdownPosition: "bottom",
    maxHeight: 250,

    // Search
    search: true,

    // Icon and Placeholder
    iconColor: globalStyles.colors.primary,
    placeholderStyle: {
      textAlign: "center",
      color: globalStyles.colors.primary,
      fontWeight: "500"
    },

    // Container Styles
    containerStyle: {
      borderRadius: 3,
      alignContent: "center",
      justifyContent: "center"
    },

    // Selected Text Style
    selectedTextStyle: {
      color: globalStyles.colors.primary,
      textAlign: "center",
      fontWeight: "500"
    },

    // Search Input Style
    inputSearchStyle: {
      outlineWidth: 0,
      borderColor: globalStyles.colors.primary,
      borderRadius: 3,
      height: 35,
      fontSize: globalStyles.sizes.fontSize
    }
  }
})`
  cursor: pointer;
  width: 90%;
  align-self: center;
  margin-top: 5px;
`
 */
