import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, AggregateColumnDirective, AggregatesDirective, AggregateDirective, AggregateColumnsDirective } from '@syncfusion/ej2-react-treegrid'
import * as S from "./styles"
import useGrid from '../Grid/hooks/useGrid'
import { Field } from '../../../services/registration/getTableFields'
import { useTranslation } from 'react-i18next'
import GridToolbar from '../Toolbars/Grid'
import { RowDataBoundEventArgs } from '@syncfusion/ej2-react-grids'
import handleRowPress from '../Grid/utils/handleRowPress'
import DynamicField from '../Grid/components/DynamicField'
import getPadronizedData from './utils/getPadronizedData'
import services from './utils/services'
import getAggregates from '../Grid/utils/getAggregates'

export default function TreeGrid({ entity, models, functions, gridRef, rowDoubleClickCallback, screenType }) {
  const { t } = useTranslation()

  const {
    fieldsData,
    records,
    updateGridRecords,
    updateGridFields
  } = useGrid({ entity, models })

  const { fields, detailTabs } = fieldsData

  const treeRecords = getPadronizedData({ records, models, entity })
  const aggregates = getAggregates(fields)

  const renderColumn = (fieldData: Field) => {
    const { ID, T_DTD_ATR } = fieldData

    const columnTemplate = props => DynamicField({
      ...props,
      fieldData,
      models,
      entity
    })

    return (
      <ColumnDirective
        field={ID}
        headerText={t(T_DTD_ATR.FIELDDESCRIPTION)}
        headerTextAlign="Center"
        key={ID}
        template={columnTemplate}
        width={200}
      />
    )
  }

  const onRowPress = (args: RowDataBoundEventArgs) => {
    handleRowPress({
      args,
      grid: gridRef.current,
      entity,
      models,
      detailTabs,
      records,
      rowDoubleClickCallback,
      screenType
    })
  }

  const pageSettings = {
    pageSize: 30,
    pageCount: 4,
    pageSizes: [30, 50, 100, 200, "All"]
  }

  const renderAggregate = ({ operation, field }) => {
    const isCurrencyField = field.FIELDTYPE.VALUE === "C"
    const isIntegerField = field.FIELDTYPE.VALUE === "I"

    const valuePrefix = isCurrencyField ? "R$ " : ""
    const defaultDecimalValue = isIntegerField ? 0 : 2

    const fixedValue = props => {
      const value = typeof props[operation] === "number" ? props[operation] : 0
      return (value).toFixed(field.T_DTD_ATR.DECIMAL ?? defaultDecimalValue)
    }

    const aggregateTemplate = props => {
      return (
        <S.FooterItem>
          <S.BoldText>{t(operation)}:</S.BoldText>
          <span>{valuePrefix} {fixedValue(props)}</span>
        </S.FooterItem>
      )
    }

    return (
      <AggregateColumnDirective
        key={field.ID}
        field={field.ID}
        type={operation}
        footerTemplate={aggregateTemplate}
      >{" "}</AggregateColumnDirective>
    )
  }

  const selectionSettings = { checkboxOnly: true }

  return treeRecords && fields.length > 0 && (
    <S.Container>
      <GridToolbar
        gridRef={gridRef}
        entity={entity}
        models={models}
        functions={{
          ...functions,
          handleRefresh: async () => {
            //await updateGridRecords()
            gridRef.current.refresh()
          }
        }}
      />
      <TreeGridComponent
        allowFiltering={true}
        allowPaging={true}
        allowReordering={true}
        allowResizing={true}
        allowSelection={true}
        allowSorting={true}
        autoCheckHierarchy={true}
        childMapping="subtasks"
        dataSource={treeRecords}
        filterSettings={{ type: "Menu" }}
        gridLines='Both'
        height="100%"//{457}
        id='TreeGrid'
        pageSettings={pageSettings}
        ref={gridRef}
        rowDataBound={onRowPress}
        selectionSettings={selectionSettings}
        showColumnChooser={true}
        showColumnMenu={true}
        treeColumnIndex={1}
        width={"100%"}
      >
        <ColumnsDirective>
          <ColumnDirective
            type='checkbox'
            width={80}
            textAlign="Center"
            headerTextAlign="Center"
          />

          {fields.map(renderColumn)}
        </ColumnsDirective>

        <AggregatesDirective>
          {aggregates.map(({ operation, fields }) => (
            <AggregateDirective key={operation}>
              <AggregateColumnsDirective>
                {fields.map((field: Field) => renderAggregate({ operation, field }))}
              </AggregateColumnsDirective>
            </AggregateDirective>
          ))}
        </AggregatesDirective>

        <Inject services={services} />
      </TreeGridComponent>
    </S.Container>
  )
}
