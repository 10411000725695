import React, { useState } from "react"
import AceEditor from "react-ace"
import { useTranslation } from "react-i18next"
import dbRequest from "../../../../../../../../../../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../../../../../../../../../../services/showAlert"
import CloseButton from "../../../../../../../../../../../CloseButton/index.web"
import ModalWrapper from "../../../../../../../../../../../Modal"
import * as S from "./styles"
import "ace-builds/src-noconflict/mode-javascript"
import "ace-builds/src-noconflict/snippets/javascript"
import "ace-builds/src-noconflict/theme-monokai"
import "ace-builds/src-noconflict/ext-language_tools"

export default function Modal({
  modalVisible,
  models,
  selectedField,
  setSelectedField,
  setModalVisible,
  setUpdateList
}) {
  const { t } = useTranslation()
  const [expression, setExpression] = useState(selectedField.expression)

  const handleSaveClick = async () => {
    showAlert({
      title: "Save expression",
      titleType: "question",
      text: "Are you sure you want to save this expression?",
      type: "confirm",
      handleConfirm: async () => {
        await dbRequest
          .saveRecords(
            `T_DTD_TAB/T_MOV_HDR/T_SET_HDR/${models.T_SET_TNS.ID}/T_DTD_FLD/${selectedField.id}/T_DTD_ATR/`
          )
          .record({ ID: "EXPRESSION", VALUE: expression })
          .execute()
        setUpdateList((s) => !s)
        closeModal()
        showAlert({
          title: "Success",
          titleType: "success",
          text: "Expression saved successfully",
          type: "ok"
        })
      }
    })
  }

  const handleChange = (newValue) => {
    setExpression(newValue)
  }

  function closeModal() {
    setModalVisible(false)
    setExpression("")
    setSelectedField(null)
  }

  return (
    <ModalWrapper isVisible={modalVisible}>
      <S.ModalContainer>
        <CloseButton callbackFunction={closeModal} />
        <S.HeaderContainer>
          <S.Div>
            <S.Title>
              {t("Default value of")} {t(selectedField.description)}
            </S.Title>
          </S.Div>
          <S.SaveButton onClick={handleSaveClick}>
            <S.SaveText>{t("Save")}</S.SaveText>
          </S.SaveButton>
        </S.HeaderContainer>
        <S.Label htmlFor="textarea">{t("Expression: ")}</S.Label>
        <AceEditor
          placeholder={t("Type your expression here")}
          mode={"javascript"}
          theme="monokai"
          name="expression"
          fontSize={14}
          lineHeight={21}
          id="textarea"
          value={expression}
          onChange={handleChange}
          setOptions={{
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true
          }}
          style={{
            width: "100%",
            padding: "0px",
            height: "300px"
          }}
        ></AceEditor>
      </S.ModalContainer>
    </ModalWrapper>
  )
}
