import { useEffect, useState, useRef, useMemo } from "react"
import { GridProps } from ".."
import applySettings from "../../Toolbars/components/CustomSettings/utils/applySettings"
import getFormattedRecords from "../utils/getFormattedRecords"
import getGridFields from "../utils/getGridFields"
import getGridRecords from "../utils/getGridRecords"

const useGrid = ({ entity, models, detailTabPath, updateGrid, gridRef }) => {
  const [records, setRecords] = useState([])
  const [fieldsData, setFieldsData] = useState({
    fields: [],
    detailTabs: []
  })
  const [loading, setLoading] = useState(false) // Estado para controlar o carregamento
  const isInitialMount = useRef(true)

  const fieldsDataMemo = useMemo(() => ({
    fields: fieldsData.fields,
    detailTabs: fieldsData.detailTabs
  }), [fieldsData.fields, fieldsData.detailTabs])

  const recordsMemo = useMemo(() => records, [records])

  useEffect(() => {

    setLoading(true)
    const fieldsPromise = getGridFields({
      entity,
      models
    })
    const recordsPromise = getGridRecords({
      entity,
      models,
      detailTabPath
    })

    Promise.all([fieldsPromise, recordsPromise])
      .then(([fields, records]) => {
        models._REGISTRATION_DATA[entity].records = records
        models._REGISTRATION_DATA[entity].initiatedRecords = records.length
        setFieldsData({
          fields: fields.sortedFields,
          detailTabs: fields.detailTabs
        })
        setRecords(records)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {

    if (!isInitialMount.current && updateGrid != null) {
      updateGridRecords()
    } else {
      isInitialMount.current = false
    }
  }, [updateGrid])

  const updateGridRecords = async () => {
    const savedProperties = models._REGISTRATION_DATA[entity].savedProperties
    const tableFields = models._REGISTRATION_DATA[entity].fields

    const newRecords = await getGridRecords({
      entity,
      models,
      detailTabPath
    })

    if (savedProperties) applySettings({
      settings: savedProperties,
      fields: tableFields,
      gridRef
    })
    models._REGISTRATION_DATA[entity].records = [...newRecords]
    gridRef.current.dataSource = getFormattedRecords({
      records: newRecords,
      models,
      entity
    })

  }

  const updateGridFields = async () => {
    const fields = await getGridFields({
      entity,
      models
    })

    setFieldsData({
      fields: fields.sortedFields,
      detailTabs: fields.detailTabs
    })
  }

  return {
    fieldsData: fieldsDataMemo,
    records: recordsMemo,
    loading,
    updateGridRecords,
    updateGridFields
  }
}

export default useGrid
