import React from "react"
import * as S from "./styles"

export default function Search(props: any) {  
  const { ID, models, entity, fieldData } = props

  const fieldId = fieldData.ID
  const recordData = models._REGISTRATION_DATA[entity].formattedRecords[ID][fieldId] ?? {}

  const value = props[fieldId]
  const hasValue = Boolean(value)
  
  const image = recordData.IMG?.[0]?.URL
  const hasImage = Boolean(image)

  return hasValue && (
    <S.Container>
      <S.ImageContainer hasValue={hasValue}>
        {hasImage ? <S.Image src={image} /> : <S.ImageIcon />}
      </S.ImageContainer>

      <span>{value}</span>
    </S.Container>
  )
}

