import update from "immutability-helper"
import React, { useCallback, useContext, useState } from "react"
import { useDrop } from "react-dnd"
import { useTranslation } from "react-i18next"
import globalStyles from "../../../../../../../../../../../global/globalStyles"
import { SearchTextContext } from "../../contexts/SearchTextContext"
import ItemTypes from "../ItemTypes"
import Card from "./components/Card"
import Modal from "./components/Modal"
import * as S from "./styles"

export default function VisibleFieldsList(props) {
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedField, setSelectedField] = useState(null)

  const { t } = useTranslation()

  const { visibleFields, setVisibleFields, selectedOption } = props

  const { searchText } = useContext(SearchTextContext)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  const isActive = canDrop && isOver

  let backgroundColor = globalStyles.colors.background

  if (isActive) backgroundColor = "#bbf7d0"
  else if (canDrop) backgroundColor = "#ffedd5"

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setVisibleFields((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    )
  }, [])

  const renderCard = useCallback((filter, index) => {
    if (filter.id === "TNSID") return

    return (
      <Card
        {...props}
        key={filter.id}
        filterData={filter}
        index={index}
        moveCard={moveCard}
        isHeaderConfiguration={isHeaderConfiguration}
        setModalVisible={setModalVisible}
        visibleFields={visibleFields}
        setSelectedField={setSelectedField}
      />
    )
  }, [])

  const filteredFields = visibleFields.filter(({ description }) => {
    const searchedValue = searchText.toUpperCase()
    const fieldDescription = t(description).toUpperCase()
    return fieldDescription.includes(searchedValue)
  })

  const isHeaderConfiguration =
    selectedOption.EXPRESSION === "T_MOV_HDR/T_SET_HDR"

  return (
    <S.Container>
      <S.Header>
        <S.Title>{t("Visible")}</S.Title>

        <S.Row>
          <S.Title style={{ fontSize: 10 }}>{t("Navigation")}</S.Title>
          <S.Title style={{ fontSize: 10 }}>{t("Editable")}</S.Title>
          {isHeaderConfiguration && (
            <S.Title style={{ fontSize: 10 }}>{t("Required")}</S.Title>
          )}
        </S.Row>
      </S.Header>

      <S.List
        ref={drop}
        data-testid="dustbin"
        style={{ backgroundColor }}
        contentContainerStyle={{ gap: 8 }}
      >
        {filteredFields.map((filter, i) => renderCard(filter, i))}
      </S.List>
      {selectedField && (
        <Modal
          setModalVisible={setModalVisible}
          {...props}
          modalVisible={modalVisible}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
        />
      )}
    </S.Container>
  )
}
