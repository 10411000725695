import { useEffect, useState } from "react"
import globalDate from "../../../../../../global/globalDate"
import evaluateFunction from "../../../../../../services/evaluateFunction"
import formatDate from "../Period/utils/formatDate"

interface UsePeriodProps {
  models: any
  field: any
  updateForm: boolean
  setUpdateForm: (s) => void
}

export default function usePeriod({ models, field, updateForm }: UsePeriodProps) {

  const { ID, TABLENAME, isFilter } = field

  const ini = ID + "_INI"
  const fin = ID + "_FIN"
  const ATR = field.T_DTD_ATR ?? {}

  const [initial, setInitial] = useState("")
  const [final, setFinal] = useState("")

  const isEditable = ATR.EDITABLE ?? true
  const isRequired = ATR.REQUIRED ?? false

  const commonProps = {
    isEditable,
    isRequired,
    onBlur: () => onSubmit()
  }

  const onSubmit = () => {
    if (!isEditable) return

    const formattedInitial = initial

    const formattedFinal = final

    const updateModelDates = () => {
      if (isFilter) {
        models._REGISTRATION_DATA[TABLENAME].filtersValues[ini] = formattedInitial
        models._REGISTRATION_DATA[TABLENAME].filtersValues[fin] = formattedFinal
      }
      else {
        models[TABLENAME][ini] = formattedInitial
        models[TABLENAME][fin] = formattedFinal
      }

      if (!formattedInitial) setInitial(formattedInitial)
      if (!formattedFinal) setFinal(formattedFinal)
    }

    updateModelDates()
  }

  useEffect(() => {

    (async () => {
      if (ATR.EXPRESSION) {
        await evaluateFunction(ATR.EXPRESSION, models, {
          setInitial: setInitial,
          setFinal: setFinal
        })
        return
      }
      const modelValue = isFilter
        ? models._REGISTRATION_DATA[TABLENAME].filtersValues
        : models[TABLENAME]

      if (modelValue[ini] || modelValue[fin]) {
        setInitial(new globalDate(modelValue[ini]).toTimestamp())
        setFinal(new globalDate(modelValue[fin]).toTimestamp())
        return
      }

      setInitial(null)
      setFinal(null)

    })()
  }, [updateForm])

  return {
    ATR,
    isRequired,
    initial,
    final,
    commonProps,
    setFinal,
    setInitial
  }
}
