import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.button`
  text-align: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 10px;
  height: 32px;
  padding: 0 10px;
  color: ${defaultTheme["gray-600"]};
  font-size: 0.875rem;
`
