import styled from "styled-components"
import { defaultTheme } from "../../../styles/themes/default"

interface MenuProps {
  menuIsExpanded: boolean
  isSelected?: boolean
}

export const Container = styled.div<MenuProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: ${({ menuIsExpanded }) => menuIsExpanded ? 260 : 63}px;
  position: fixed;
  box-shadow: 4px 0 5px #00000029;
  background-color: ${({ theme }) => theme["white"]};
  transition: width .3s ease-in-out;
  z-index: 999;
`

export const Background = styled.div`
  width: calc(100vw - 260px);
  height: 100vh;
  background-color: black;
  opacity: 0.3;
  position: absolute;
  margin-left: 260px;
  cursor: pointer;
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 14px;
  padding-left: 6px;
  margin-bottom: 20px;
  overflow: hidden;
  white-space: nowrap;
`

export const UserNameText = styled.h2<MenuProps>`
  color: ${({ theme }) => theme["cyan-700"]};
  font-size: 18px;
  font-weight: bold;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};

  transition: opacity .2s ease-in-out;
`

export const ButtonWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 6px;
  padding-right: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;
  opacity: 1;
  transition: opacity .16s ease-in-out;

  &:active {
    opacity: 0.3;
  }
`

export const MenuButton = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 14%;
`

export const MenuText = styled.p<MenuProps>`
  color: ${({ theme, isSelected }) => isSelected ? theme["cyan-300"] : theme["gray-700"]};
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  
  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};
  cursor: pointer;
  &:active {
    opacity: 0.3;
  }
`

export const NavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5%;
  flex: 1;
  width: 100%;
  margin-top: 35px;
  
`

export const SeparatorLine = styled.div`
  width: 70%;
  height: 1px;
  border-radius: 1px;
  background-color: ${({ theme }) => theme["gray-400"]};
  align-self: center;
  margin: 8px 18%;
`

export const FooterWrapper = styled.div`
  flex: .5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10%;
  padding: 8px 0 14px 0;
`

export const OpenNotificationsButton = styled(MenuButton)`
  display: flex;
  align-items: center;
  border-style: none;
  background-color: transparent;
  width: 50px;
  cursor: pointer;
`

export const ButtonText = styled(MenuText)`
  font-weight: 500;
`

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  white-space: normal;
  color: ${({ theme }) => theme["gray-700"]};
`

export const UserFullName = styled.span<MenuProps>`
  font-size: 12px;
  font-weight: 500;
  text-align: start;

  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};

  margin-bottom: 3px;
`

export const UserEmail = styled.span<MenuProps>`
  font-size: 10px;
  transition: opacity .3s ease-in-out;
  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};
`

export const SelectedMenuHighlighter = styled.div`
  position: absolute;
  height: 100%;
  width: 5px;
  left: 0;
  border-radius: 0 2px 2px 0;
  background-color: ${({ theme }) => theme["cyan-300"]};
`

export const BranchSwapButton = styled.button<MenuProps>`
  position: absolute;
  right: 10px;
  padding: 2px;
  border-radius: 4px;
  background-color: ${defaultTheme["cyan-700"]};
  border: none;
  cursor: pointer;

  opacity: ${({ menuIsExpanded }) => menuIsExpanded ? 1 : 0};

  transition: opacity .2s ease-in-out;

  &:hover {
    background-color: orange;
    transition: background-color .2s ease-in-out; 
  }

`
