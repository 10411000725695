import { Platform } from "react-native"
import dbRequest from "../services/dbRequest/dbRequest"
import evaluateFunction from "../services/evaluateFunction"

const orderedFields = (fields) => {
  return fields.sort((a, b) => a?.T_DTD_ATR?.ORDERBY - b?.T_DTD_ATR?.ORDERBY)
}

export default class JumpScreenController {

  static async start(screensList, navigation, OrderComposerController) {
    const { indexScreen, instanciatedScreens } = this.getIndexScreen(screensList)

    if (instanciatedScreens !== 1) return

    const screenController = indexScreen.controller
    OrderComposerController.jumpScreenStatus = true
    const selectedTransaction = screenController.models().T_SET_TNS
    const navigationFields = orderedFields(OrderComposerController.sideBarFields)

    await this.generateController(screensList, screenController) //predefinedNavigationData
    const nextScreen = await this.getNextScreen(screensList, selectedTransaction, navigationFields)

    Platform.OS === "web" ?
      navigation.navigate(OrderComposerController.tabName, { screen: nextScreen })
      : navigation.navigate(nextScreen)
  }

  static getIndexScreen(screensList) {
    let indexScreen = null
    let instanciatedScreens = 0

    for (const screen in screensList) {
      if (screensList[screen].controller.saved) {
        indexScreen = screensList[screen]
        instanciatedScreens++
      }
    }

    if (!indexScreen) throw new Error("No saved controller found.")

    return {
      indexScreen,
      instanciatedScreens
    }
  }

  static async generateController(screensList, screenController) {

    const screensPromises = []
    for (const screenId in screensList) {
      const screen = screensList[screenId]

      if (!screen.defaultValue) continue

      const defaultValue = await evaluateFunction(screen.defaultValue, screenController.models())
      screensPromises.push(await this.populateModels(
        defaultValue,
        screen.collectionName,
        screen.HDRfield.ID,
        screenController,
        screensList
      ))
    }

    await Promise.all(screensPromises)
  }

  static async populateModels(defaultValue, screenEntity, screenID, screenController, screensList) {

    const record = await dbRequest
      .loadRecords(screenEntity)
      .where("ID", "==", `${defaultValue}`)
      .execute(r => r?.[0])

    await this.executeTriggers(screenController.models(), {
      ENTITY: screenEntity,
      RECORD: record
    })

    if (record) {
      screenController.models().T_MOV_HDR[screenID] = {
        ID: record.ID,
        DESCRIPTION: record.DESCRIPTION
      }
      screenController.models()[screenEntity] = record
      screensList[screenEntity].controller.saved = true
    }
  }

  static async getNextScreen(screenList, selectedTransction, navigationFields) {
    let lastScreen = null
    let collectionName = null

    for (const screen of Object.keys(screenList)) {
      if (!screenList[screen].controller.saved) {
        lastScreen = screenList[screen].name
        collectionName = screen
        break
      }
    }

    if (lastScreen === "Observation" && selectedTransction.SKIPHEADER) {
      await this.populateHDRModels(screenList.T_MOV_HDR.controller.models(), navigationFields)
      await screenList[collectionName].controller.saveValues()
      screenList[collectionName].controller.saved = true
      lastScreen = "Products"
    }

    return lastScreen
  }

  static async getTriggers() {
    return await dbRequest
      .loadRecords("T_DTD_TAB/T_MOV_HDR/T_DTD_TRG")
      .execute(r => r.sort((a, b) => a.ORDERBY - b.ORDERBY))
  }

  static async executeTriggers(models, params) {
    const validated = []

    const triggers = await this.getTriggers()

    const triggerPromises = triggers.map(async trigger => {
      if (!trigger.ACTIVE) validated.push(true)
      else if (await evaluateFunction(trigger.EXPRESSION, models, params)) validated.push(true)
    })

    await Promise.all(triggerPromises)

    return validated.length === triggers.length
  }

  static async populateHDRModels(models, navigationFields) {
    const fieldPromises = Object.keys(navigationFields).map(async key => {
      if (navigationFields[key].T_DTD_ATR?.EXPRESSION) {
        let defaultValue = await evaluateFunction(navigationFields[key].T_DTD_ATR?.EXPRESSION, models)

        if ((navigationFields[key].FIELDTYPE.VALUE === "K" || navigationFields[key].FIELDTYPE.VALUE === "N")) {
          defaultValue = await dbRequest
            .loadRecords(navigationFields[key].T_DTD_ATR.ENTITY.ID)
            .where("ID", "==", defaultValue)
            .execute(r => r[0])
        }
        models.T_MOV_HDR[key] = { ID: defaultValue.ID }
      }
    })

    await Promise.all(fieldPromises)
  }
}
