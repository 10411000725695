import React from "react"
import Access from "./DynamicModal/components/Access"
import ConfigurationModal from "./DynamicModal/components/ConfigurationModal"
import ParametersForm from "./DynamicModal/components/ParametersForm"
import ReceitasWS from "./DynamicModal/components/ReceitaWS"

export default function ModalContent(props) {
  const { TYPE, EXPRESSION } = props.selectedOption

  if (TYPE.VALUE === "E") return

  if (TYPE.VALUE === "N") { //Native functions
    if (EXPRESSION === "fnc_receitaWS")
      return <ReceitasWS {...props} />

    //if (EXPRESSION === "fnc_importSpreadsheet") 
    //  return <ImportSpreadSheet entityName={entityName} controller={controller} />
    //

    if (EXPRESSION === "fnc_access") // Access modal
      return <Access {...props} />
  }

  if (["T_MOV_HDR/T_SET_HDR", "T_MOV_ITE/T_SET_ITE"].includes(EXPRESSION)) // Header and Items personalization
    return <ConfigurationModal {...props} />


  if (TYPE.VALUE === "F") // Functions with expression to evaluate
    return <ParametersForm {...props} />
}
