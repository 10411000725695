import dbRequest from "../../services/dbRequest/dbRequest"
import evaluateFunction from "../../services/evaluateFunction"
import removeFieldsFromObject from "../../services/removeFieldsFromObject"
import showAlert from "../../services/showAlert"

export default class DefaultController {

  constructor(params) {

    this.modelsTemplate = params.models()
    this.models = () => this.modelsTemplate
    this.OrderComposerController = params.orderComposerController
    this.saved = params.saved

    this.filters = params.filters
    this.screenName = params.name
    this.collectionName = params.collectionName
    this.listEntityName = () => params.collectionName
    this.entityFields = () => params.entityFields
    this.getHDRfield = () => params.HDRfield

    this.saveValues = async item => {
      this.models().T_MOV_HDR[params.HDRfield.ID] = {
        ID: item.ID,
        DESCRIPTION: item.DESCRIPTION,
        IMG: item.IMG?.[0]?.URL ?? ""
      }
      Object.keys(item).forEach(key => this.models()[this.listEntityName()][key] = item[key])

      if (this.models().T_MOV_HDR.ID) {
        await dbRequest
          .saveRecords("T_MOV_HDR")
          .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
          .execute(() => {
            showAlert({
              titleType: "success",
              title: " ",
              text: "Alteração salva com sucesso",
              type: "ok"
            })
          })
      }
    }
  }

  async getRecords(lastDocument, setLastDocument, recordsLength, searchText, applyFilters) {
    const filtersArray = await evaluateFunction(this.filters ?? "return []", this.models())

    let request = dbRequest.loadRecords(this.listEntityName())

    if (!applyFilters) {
      //TODO filters
      return await request
        //.lastDocument(lastDocument)
        //.hasLimit()
        .execute((data) => {
          //lastDocument && setLastDocument(lastDocument)
          return this.applyDefaultFilters(data, filtersArray)
        })
    } else {
      let filteredRecords = []

      for (const field of this.searchFilters) {
        await request
          .search({
            field: field,
            searchText: searchText
          })
          .execute(r => r.map(record => filteredRecords.push(record)))
      }

      const uniqueArray = filteredRecords.filter((obj, index, self) =>
        index === self.findIndex((o) => o.ID === obj.ID)
      )

      return this.applyDefaultFilters(uniqueArray, filtersArray)
    }
  }

  getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj)
  }

  applyDefaultFilters = (data, filters) => {

    const finalFilteredArray = data.filter(record => {
      return filters.every(({ field, operator, value }) => {
        const fieldValue = this.getNestedValue(record, field)
        switch (operator) {
          case "==":
            return fieldValue == value
          case "!=":
            return fieldValue != value
          case ">":
            return fieldValue > value
          case ">=":
            return fieldValue >= value
          case "<":
            return fieldValue < value
          case "<=":
            return fieldValue <= value
          case "array-contains":
            return Array.isArray(fieldValue) && fieldValue.includes(value)
          default:
            return false
        }
      })
    })

    return finalFilteredArray
  }

  startChangeScreen = (i, setIsLoading) => {
    this.OrderComposerController.changeScreen(i, this, setIsLoading)
  }

  formatCpfCnpj = (value) => {
    return value?.length === 11
      ? value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      : value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
  }
}
