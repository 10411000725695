import { MaterialIcons } from "@expo/vector-icons"
import Ionicons from "@expo/vector-icons/Ionicons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { resetState } from "../../../../redux/redux-reducers/dashboard-reducer"
import { defaultTheme } from "../../../../styles/themes/default"
import Form from "../../../../views/components/Form"
import ModalWrapper from "../../../../views/components/Modal"
import showDashboards from "../../utils/showDashboards"
import validationFields from "../SideBarSearchFields/utils/validationFields"
import * as S from "./styles"

const FilterModal = ({
  idDashboard,
  idfilial,
  controller,
  entityName,
  fields,
  isVisible,
  setIsVisible,
  isValid
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [updateForm, setUpdateForm] = useState(false)

  const handleClearFields = () => {
    controller.models().DASHBOARD.reset()
    setUpdateForm((s) => !s)
  }

  const handleRefresh = async () => {
    if (await validationFields(controller, entityName, idDashboard, idfilial))
      dispatch(resetState())
  }

  const handleApplyFilters = async () => {
    await handleRefresh()
    if (showDashboards(fields, controller.models()))
      setIsVisible((state) => !state)
  }

  return (
    <>
      <S.ButtonsContainer
        style={{ visibility: isValid ? "visible" : "hidden" }}
      >
        <S.Button onClick={() => setIsVisible((s) => !s)}>
          <Ionicons name="filter" color={defaultTheme["gray-700"]} size={16} />
          {t("Filters")}
        </S.Button>

        <S.Button onClick={handleRefresh}>
          <Ionicons name="reload" color={defaultTheme["gray-700"]} size={16} />
          {t("Refresh")}
        </S.Button>
      </S.ButtonsContainer>

      {isVisible && (
        <ModalWrapper isVisible={isVisible}>
          <S.ResetStyles />
          <S.ModalContainer>
            
            <S.HeaderContainer>
              <S.ModalTitle>{t("Filters")}</S.ModalTitle>
              <S.HeaderButtonsView>
                
                <S.Clear onClick={handleClearFields}>
                  {t("Clear")}
                </S.Clear>
                <S.ButtonHeader onClick={handleApplyFilters}>
                  {t("Apply Filters")}
                </S.ButtonHeader>
              </S.HeaderButtonsView>
              <S.CloseButton onClick={() => setIsVisible((s) => !s)}>
              <MaterialIcons name="close" size={20} color={defaultTheme["gray-700"]} />
            </S.CloseButton>
            </S.HeaderContainer>

            <S.Body>
              <S.FormView>
                <Form
                  staticFields={fields}
                  entity={"DASHBOARD"}
                  models={controller.models()}
                  updateForm={updateForm}
                  hideTabs={true}
                />
              </S.FormView>
            </S.Body>
          </S.ModalContainer>
        </ModalWrapper>
      )}
    </>
  )
}

export default FilterModal
