import React, { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { SearchTextContext } from "../../contexts/SearchTextContext"
import Card from "./components/Card"
import * as S from "./styles"

export default function NonVisibleFieldsList(props) {
  const { t } = useTranslation()

  const { nonVisibleFields } = props

  const { searchText } = useContext(SearchTextContext)

  const renderCard = useCallback((field) => {
    return (
      <Card
        {...props}
        key={field.id}
        fieldData={field}
      />
    )
  }, [])

  const filteredFields = nonVisibleFields.filter(({ description }) => {
    const searchedValue = searchText.toUpperCase()
    const fieldDescription = t(description).toUpperCase()
    return fieldDescription.includes(searchedValue)
  })

  return (
    <S.Container>
      <S.Title>{t("Not Visible")}</S.Title>

      <S.List contentContainerStyle={{ gap: 8 }}>
        {filteredFields.map(renderCard)}
      </S.List>
    </S.Container >
  )
}
