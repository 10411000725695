/* eslint-disable no-case-declarations */
import { ColumnDirective } from "@syncfusion/ej2-react-grids"
import React from "react"
import { ImgTemplate } from "../ImgTemplate"

const RenderColumn = ({ fieldData }) => {
  const { ID, T_DTD_ATR, FIELDTYPE } = fieldData

  const commonProps = {
    key: ID,
    allowEditing: ID !== "ID",
    field: ID,
    headerText: T_DTD_ATR.FIELDDESCRIPTION,
    headerTextAlign: "Center",
    textAlign: "Center"
  }

  switch (FIELDTYPE.VALUE) {
    case "B":
      commonProps.type = FIELDTYPE.VALUE === "B" ? "boolean" : "string"
      commonProps.displayAsCheckBox = true
      break
    case "I":
      commonProps.type = "number"
      break

    case "C":
      commonProps.formatter = (fieldData, data) => {
        const { field } = fieldData
        const value = data[field]

        if (!value && value !== 0) return 0

        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: T_DTD_ATR.DECIMAL ?? 2,
          minimumFractionDigits: T_DTD_ATR.DECIMAL ?? 2
        }).format(value)
      }
      break

    case "F":
      commonProps.type = "number"
      commonProps.formatter = (fieldData, data) => {
        const { field } = fieldData
        let value = data[field]

        if (!value && value !== 0) value = 0

        return new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          minimumFractionDigits: T_DTD_ATR.DECIMAL ?? 2,
          maximumFractionDigits: T_DTD_ATR.DECIMAL ?? 2
        }).format(value)
      }
      break

    case "A":
      commonProps.template = (props) => <ImgTemplate {...props} />
      break

    case "D":
      commonProps.type = "date"
      commonProps.format = "dd/MM/yyyy"
      break

    case "T":
      commonProps.type = "dateTime"
      commonProps.format = "dd/MM/yyyy HH:mm:ss"
      break

    default:
      commonProps.type = "string"
      break

  }

  return <ColumnDirective {...commonProps} />
}

export default RenderColumn
