import styled from "rn-css"
import { defaultTheme } from "../../../../../../../../../../../../../styles/themes/default"

export const Container = styled.View`
  background-color: ${defaultTheme["white"]};

  width: 200px;
  padding: 8px;

  border-radius: 4;

  cursor: grab;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`

export const Text = styled.Text`
  font-size: 12px;

  color: ${defaultTheme["gray-900"]};
`
