import React from "react"
import { ActivityIndicator, Platform, View, VirtualizedList } from "react-native"
import globalStyles from "../../../global/globalStyles"
import listManager from "./hook/listManager"

export default function List({ controller, changeScreen, Card, searchText = "", applyFilters, update }) {

  const isWeb = Platform.OS === "web"

  const {
    renderItem, getItem, getItemCount, extractListKeys, records, filteredRecords, filtered, isLoading, loadData, hasMoreToLoad, loadingMoreData
  } = listManager(controller, changeScreen, Card, searchText, applyFilters, update)

  const renderData = filtered ? filteredRecords : records

  if (isLoading) return (
    <View style={{
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    }}>
      <ActivityIndicator size="large" color={globalStyles.colors.primary} />
    </View>

  )
  else return <VirtualizedList
    data={renderData}
    initialNumToRender={isWeb ? 30 : 10}
    contentContainerStyle={{
      paddingBottom: isWeb ? 10 : 150,
      paddingTop: 15
    }}
    renderItem={renderItem}
    getItem={getItem}
    getItemCount={getItemCount}
    keyExtractor={extractListKeys}
    onEndReached={() => hasMoreToLoad && loadData(searchText)}
    onEndReachedThreshold={1}
    ListFooterComponent={() => {
      return loadingMoreData && (
        <View style={{ marginVertical: 20 }}>
          <ActivityIndicator size="large" color={globalStyles.colors.primary} />
        </View>
      )
    }}
  />

}
