import { Platform } from "react-native"
import models from "../models"
import dbRequest from "../services/dbRequest/dbRequest"
import evaluateFunction from "../services/evaluateFunction"
import getTableFields from "../services/registration/getTableFields"
import JumpScreenController from "./JumpScreenController"
import DefaultController from "./orderControllers/DefaultController"
import HeaderController from "./orderControllers/HeaderController"
import OrderItemsController from "./orderControllers/OrderItemsController"
import ProductsController from "./orderControllers/ProductsController"

export default class OrderComposerController {
  constructor() {
    this.modelsTemplate = models.getClone()
    this.models = () => this.modelsTemplate
    this.orderPrototype = {}
    this.jumpScreenStatus = false
    this.sideBarFields = []
    this.headerFormFields = []
  }

  async initialize(setIsLoading = () => null) {

    setIsLoading(true)

    const fields = await this.fetchAndSortFields("T_SET_TNS")

    this.models().T_MOV_HDR.STATUS = {
      LABEL: "In Attendance",
      VALUE: "A"
    }

    const HDRfield = {
      ID: "TNSID",
      LABEL: "DESCRIPTION"
    }

    const tnsFilter = await dbRequest.loadRecords("T_DTD_TAB/T_MOV_HDR/T_DTD_FLD/TNSID/T_DTD_ATR").where("ID", "==", "FILTER")
      .execute(response => response[0]?.VALUE ?? null)

    this.orderPrototype.T_SET_TNS = this.createOrderPrototype({
      collectionName: "T_SET_TNS",
      HDRfield,
      name: "Transaction",
      screenOrder: 0,
      saved: true,
      entityFields: fields,
      filters: tnsFilter
    })
    this.orderPrototype.T_SET_TNS.controller = new DefaultController(this.orderPrototype.T_SET_TNS)

    this.orderPrototype.T_SET_TNS.controller.saved = true

    await this.getTriggers()
    this.jumpScreenStatus = false
  }

  async fetchAndSortFields(entityName) {
    const fields = await getTableFields({ entityName })
    return fields.sort((a, b) => a.T_DTD_ATR.CARDORDERBY - b.T_DTD_ATR.CARDORDERBY)
  }

  createOrderPrototype({
    collectionName,
    HDRfield,
    filters = null,
    name,
    entityFields,
    screenOrder,
    saved = false,
    formFields = null,
    defaultValue = null
  }) {

    return {
      id: 0,
      name,
      collectionName,
      HDRfield,
      filters: filters,
      models: this.models,
      orderComposerController: this,
      entityFields,
      saved,
      formFields: formFields,
      screenOrder,
      defaultValue
    }
  }

  async changeScreen(item, controller, setIsLoading) {
    controller.saved = true

    if (controller.screenName === "Transaction") {
      await this.getNavigationFields(item.ID)
    }

    const shouldExecuteTriggers = await this.executeTriggers(this.models(), {
      ENTITY: controller.collectionName,
      RECORD: item
    })

    if (shouldExecuteTriggers) {
      await controller.saveValues(item)

      if (!this.jumpScreenStatus) {
        await JumpScreenController.start(this.orderPrototype, this.navigation, this)
      } else {
        this.navigateToNextScreen()
      }
    }

    if (controller.screenName !== "Order Summary") setIsLoading(s => !s)
  }

  async getNavigationFields(transactionId, existingOrder) {

    const saved = existingOrder ?? false

    let navigationFields = await this.getFilteredNavigationFields(transactionId, "T_MOV_HDR").then(fields => {
      return fields.filter(field => field.T_DTD_ATR.VISIBLE)
    })

    this.sideBarFields = navigationFields.filter(item => item.FIELDTYPE.VALUE === "N")

    this.headerFormFields = navigationFields
      .filter(({ FIELDTYPE }) => FIELDTYPE.VALUE !== "N")
      .sort((a, b) => a.T_DTD_ATR.ORDERBY - b.T_DTD_ATR.ORDERBY)

    if (!navigationFields.length) {
      navigationFields = await this.getFallbackFields("T_MOV_HDR")
    }

    await this.createOrderTemplate(navigationFields, transactionId, saved)
    await this.setScreens(this.generateScreenArray())
  }

  async getFilteredNavigationFields(id, entityName) {
    return await getTableFields({
      collectionPath: `T_MOV_HDR/T_SET_HDR/${id}`,
      entityName
    })
  }

  async getFallbackFields(entityName) {
    const navigationFields = await getTableFields({
      entityName,
      fetchOptions: false
    })
    this.sideBarFields = navigationFields.filter(item => item.FIELDTYPE.VALUE === "N")
    return navigationFields.filter(item => item.FIELDTYPE.VALUE === "N" && item.ID !== "TNSID")
  }

  async createOrderTemplate(navigationFields, transactionId, saved) {

    const prototypePromises = navigationFields
      .filter(item => item.FIELDTYPE.VALUE === "N" && item.ID !== "TNSID")
      .map(async (field, index) => {
        const HDRfield = {
          ID: field.ID,
          LABEL: field.T_DTD_ATR.LABEL
        }

        const entityId = field.T_DTD_ATR.ENTITY.ID
        const entityFields = await this.getEntityFields(entityId)
        this.orderPrototype[entityId] = this.createOrderPrototype({
          collectionName: entityId,
          HDRfield,
          name: field.T_DTD_ATR.FIELDDESCRIPTION,
          screenOrder: index + 1,
          entityFields,
          saved,
          filters: field.T_DTD_ATR.FILTER,
          defaultValue: field.T_DTD_ATR.EXPRESSION
        })
        this.orderPrototype[entityId].controller = new DefaultController(this.orderPrototype[entityId])
        this.setScreens(prevState => this.addScreen(prevState, field.T_DTD_ATR.FIELDDESCRIPTION, this.orderPrototype[entityId]))
      })

    await Promise.all(prototypePromises)

    const entries = Object.entries(this.orderPrototype)

    entries.sort((a, b) => a[1].screenOrder - b[1].screenOrder)
    this.orderPrototype = Object.fromEntries(entries)

    const tabs = navigationFields
      .filter(item => item.FIELDTYPE.VALUE !== "N")
      .map(({ T_DTD_ATR }) => T_DTD_ATR?.TAB?.DESCRIPTION ? T_DTD_ATR?.TAB : { DESCRIPTION: "Additional Details" })

    const sortedTabs = tabs.sort((a, b) => a.ORDERBY - b.ORDERBY).map(({ DESCRIPTION }) => DESCRIPTION)

    const uniqueTabs = [...new Set(sortedTabs)]

    uniqueTabs.forEach(tabName => {
      const tabFields = this.headerFormFields.filter(field => {
        return field.T_DTD_ATR?.TAB?.DESCRIPTION === tabName || (!field.T_DTD_ATR?.TAB?.DESCRIPTION && tabName === "Additional Details")
      }).map(field => {
        return {
          ...field,
          T_DTD_ATR: {
            ...field.T_DTD_ATR,
            TAB: { DESCRIPTION: "Main" }
          }
        }
      })

      this.orderPrototype[`TAB_${tabName}`] = this.createOrderPrototype({
        collectionName: `TAB_${tabName}`,
        HDRfield: {
          ID: "TAB",
          LABEL: tabName
        },
        name: tabName,
        screenOrder: Object.keys(this.orderPrototype).length,
        entityFields: [],
        saved,
        formFields: tabFields
      })
      this.orderPrototype[`TAB_${tabName}`].controller = new HeaderController(this.orderPrototype[`TAB_${tabName}`])
    })

    const [recIteFields, recIteCustomFields] = await Promise.all([
      this.getEntityFields("T_MOV_ITE"),
      getCustomFields(transactionId)
    ])

    this.orderPrototype["T_REC_ITE"] = this.createOrderPrototype({
      collectionName: "T_REC_ITE",
      HDRfield: "ITE",
      name: "Products",
      screenOrder: Object.keys(this.orderPrototype).length + 1,
      entityFields: recIteFields,
      saved
    })
    this.orderPrototype["T_REC_ITE"].ItemsHeaderFields = recIteFields
    this.orderPrototype["T_REC_ITE"].customFields = recIteCustomFields
    this.orderPrototype["T_REC_ITE"].controller = new ProductsController(this.orderPrototype["T_REC_ITE"])

    this.orderPrototype["T_MOV_ITE"] = this.createOrderPrototype({
      collectionName: "T_MOV_ITE",
      HDRfield: "ITE",
      name: "Order Summary",
      screenOrder: Object.keys(this.orderPrototype).length + 1,
      entityFields: [],
      saved
    })
    this.orderPrototype["T_MOV_ITE"].ItemsHeaderFields = recIteFields
    this.orderPrototype["T_MOV_ITE"].customFields = recIteCustomFields
    this.orderPrototype["T_MOV_ITE"].controller = new OrderItemsController(this.orderPrototype["T_MOV_ITE"])
  }

  addScreen(prevState, name, params) {
    const newScreens = [...prevState]
    if (!newScreens.find(screen => screen.name === name)) {
      newScreens.push({
        name,
        params
      })
    }
    return newScreens
  }

  generateScreenArray() {
    return Object.values(this.orderPrototype)
      .filter(element => element.name !== "Transaction")
      .map(element => ({
        name: element.name,
        params: element
      }))
  }

  navigateToNextScreen() {
    const nextScreenName = this.getNextScreenName()
    const targetScreen = nextScreenName ?? "Order Summary"

    if (Platform.OS === "web") {
      this.navigation.navigate(this.tabName, { screen: targetScreen })
    } else {
      this.navigation.navigate(targetScreen)
    }
  }

  getNextScreenName() {
    return Object.values(this.orderPrototype).find(screen => !screen.controller.saved)?.controller.screenName
  }

  async getEntityFields(entity) {
    return (await getTableFields({ entityName: entity }))
      .sort((a, b) => (a?.T_DTD_ATR?.CARDORDERBY ?? Infinity) - (b?.T_DTD_ATR?.CARDORDERBY ?? Infinity))
  }

  async getTriggers() {
    this.triggers = (await dbRequest
      .loadRecords("T_DTD_TAB/T_MOV_HDR/T_DTD_TRG")
      .execute()).sort((a, b) => a.ORDERBY - b.ORDERBY)
  }

  async executeTriggers(models, params) {
    for (const trigger of this.triggers) {
      if (trigger.ACTIVE && !(await evaluateFunction(trigger.EXPRESSION, models, params))) {
        return false
      }
    }
    return true
  }

  async goToScreen(selectedItem) {
    await this.models().T_MOV_HDR.populate(selectedItem)
    //await this.getTriggers()
  }
}

async function getCustomFields(id) {
  let fields = await getTableFields({
    collectionPath: `T_MOV_ITE/T_SET_ITE/${id}`,
    entityName: "T_MOV_ITE"
  })

  if (!fields.length) {
    fields = await getTableFields({ entityName: "T_MOV_ITE" })
  }

  return fields.sort((a, b) => (a?.T_DTD_ATR?.CARDORDERBY ?? Infinity) - (b?.T_DTD_ATR?.CARDORDERBY ?? Infinity))
}
