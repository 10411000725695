import LoginData from "../../../models/loginData"
import dbRequest from "../../dbRequest/dbRequest"

const getLauncherScreens = async ({ menuData, rootParentID = null, userPermissions, onlyRegistrations = false }) => {

  const updatedButtons = await Promise.all(menuData.map(async (button) => {
    const launchers = await fetchLaunchers(button, onlyRegistrations)

    if (launchers.length > 0)
      button.SUB_MENUS = applyPermissionsFilter({
        launchers,
        userPermissions
      })

    const currentRootParentID = rootParentID || button.DESCRIPTION

    if (button.SUB_MENUS?.length > 0)
      button.SUB_MENUS = await getLauncherScreens({
        menuData: button.SUB_MENUS,
        rootParentID: currentRootParentID,
        userPermissions
      })

    return {
      ...button,
      RootParentID: currentRootParentID
    }
  }))

  return updatedButtons
}

export default getLauncherScreens

async function fetchLaunchers(routine, onlyRegistrations) {
  const { ID, TYPE } = routine

  const entities = {
    Registration: "T_DTD_TAB",
    Hierarchic: "T_DTD_TAB",
    OrderPanel: "T_DTD_TAB",
    Records: "T_DTD_TAB",
    NavigationPanel: "T_DTD_TAB",
    Dashboard: "T_RDB_DSB",
    Reports: "T_RDB_REP"
  }

  const entity = entities[TYPE.VALUE]

  const launchers = await dbRequest
    .loadRecords(entity)
    .where("NAVID", "==", ID)
    .execute((res) => {
      if (onlyRegistrations) return res.filter((launcher) => launcher?.TYPE?.VALUE === "Registration")
      else return res
    }) as any

  return launchers.map(launcher => ({
    ID: launcher.ID,
    DESCRIPTION: entity === "T_DTD_TAB" ? launcher.ENTITY : launcher.DESCRIPTION,
    TYPE: entity === "T_DTD_TAB" ? launcher.TYPE : { VALUE: TYPE.VALUE },
    ORDERBY: launcher.ORDERBY,
    ONMOB: launcher?.ONMOB ?? false,
    isLauncher: true
  }))
}

function applyPermissionsFilter({ launchers, userPermissions }) {
  const isSystemAdmin = LoginData.getLoginData().ADMIN

  if (isSystemAdmin) return launchers
  return launchers.filter(({ ID }) => userPermissions[ID]?.READ)

}
