/* eslint-disable react/display-name */
import { useIsFocused } from "@react-navigation/native"
import { memo, useCallback, useEffect, useState } from "react"
import React from "react-native"
import PriceTableData from "../../../../models/priceTableData"
import useAppSelector from "../../../../redux/hooks/useAppSelector"

export default function listManager(controller, changeScreen, Card, searchText, applyFilters, update) {

  const additionalFilters = useAppSelector(({ orderReducer }) => orderReducer.additionalFilters)
  const isFocused = controller.screenName === "Order Summary" && useIsFocused()

  const ListItem = memo(({ item }) => (
    <Card item={item} controller={controller} onHandlePress={changeScreen} />
  ))

  const renderItem = useCallback(({ item }) => <ListItem item={item} />, [ListItem])
  const getItem = useCallback((_data, index) => _data[index], [])
  const getItemCount = useCallback((_data) => _data.length, [])
  const extractListKeys = useCallback((item, index) => index.toString(), [])

  const [filtered, setFiltered] = useState(false)
  const [records, setRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [lastDocument, setLastDocument] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true)
  const [loadingMoreData, setLoadingMoreData] = useState(false)

  const loadData = async (searchText) => {
    let data = []

    if (!applyFilters && additionalFilters.length === 0) {
      setFiltered(false)
      setHasMoreToLoad(true)

      if (hasMoreToLoad || controller.collectionName === "T_MOV_ITE") {
        setHasMoreToLoad(true)
        data = await controller.getRecords(lastDocument, setLastDocument, records.length, searchText, applyFilters)

        if (controller.collectionName === "T_MOV_ITE") {
          setRecords(data)
          setHasMoreToLoad(false)
          return setIsLoading(false)
        }

        if (data.length === 0) return setHasMoreToLoad(false)

        setRecords(prevData => {
          const combinedData = [...prevData, ...data]
          const uniqueData = combinedData.filter(
            (item, index, self) => index === self.findIndex((t) => t.ID === item.ID)
          )
          return uniqueData
        })
        setLoadingMoreData(false)
      }
    } else {
      setIsLoading(true)
      setRecords([])
      setFiltered(true)
      setLastDocument(null)

      data = await controller.getRecords(lastDocument, () => null, records.length, searchText, applyFilters)
      const filteredData = filterAdditionalFilters(data)
      setFilteredRecords(filteredData)
      setHasMoreToLoad(false)
    }

    setIsLoading(false)
  }

  const filterAdditionalFilters = (data) => {

    if (controller.collectionName !== "T_REC_ITE") return data
    return additionalFilters.reduce((filteredArray, filter) => {
      const priceTable = PriceTableData.getPRICETABLEDATA()
      return filteredArray.filter(record => {
        if (filter.LABEL === "General") {
          switch (filter.VALUE[0]) {
            case "Promotion": return priceTable[record.ID].PROMOTION
            case "Replenishment": return priceTable[record.ID].REPLENISHMENT
            case "Stock": return priceTable[record.ID].STOCK > 0
            default: return true
          }
        }

        if (filter.LABEL === "GROUPITEMID") return filter.VALUE.includes(record[filter.LABEL].ID)
        return filter.VALUE.includes(record[filter.LABEL])

      })
    }, data)
  }

  useEffect(() => {
    loadData(searchText)
  }, [update, isFocused, filtered])

  return {
    renderItem,
    getItem,
    getItemCount,
    extractListKeys,
    records,
    filteredRecords,
    filtered,
    isLoading,
    loadData,
    hasMoreToLoad,
    loadingMoreData
  }
}
