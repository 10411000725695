import AsyncStorage from "@react-native-async-storage/async-storage"
import de from "../utils/i18n/de.json"
import en from "../utils/i18n/en.json"
import es from "../utils/i18n/es.json"
import fr from "../utils/i18n/fr.json"
import ja from "../utils/i18n/ja.json"
import pt from "../utils/i18n/pt.json"
import dbRequest from "./dbRequest/dbRequest"

export default async function setTranslations() {
  const translations = await dbRequest.loadRecords("T_DTD_I18N").execute()

  const languages = [
    {
      jsonFile: de,
      description: "de"
    },
    {
      jsonFile: pt,
      description: "pt"
    },
    {
      jsonFile: en,
      description: "en"
    },
    {
      jsonFile: es,
      description: "es"
    },
    {
      jsonFile: fr,
      description: "fr"
    },
    {
      jsonFile: ja,
      description: "ja"
    }
  ]

  const storageOperations = []

  for (const translation of translations) {
    for (const language of languages) {
      const { jsonFile, description } = language

      jsonFile.translation[translation.ID] = translation[description] ?? translation.ID
      const storageOperation = AsyncStorage.setItem(description, JSON.stringify({ ...jsonFile }))
      storageOperations.push(storageOperation)
    }
  }

  await Promise.all(storageOperations)
}
