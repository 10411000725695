import React, { useRef, useState } from "react"
import { defaultTheme } from "../../../../../../styles/themes/default"
import Grid from "../../../../Grid"
import LoadingAnimation from "../../../../LoadingAnimation"
import ModalWrapper from "../../../../Modal"
import FormModal from "./Modal"
import * as S from "./styles.web"
import handleCopy from "./utils/ButtonsFunctions/handleCopy"
import handleDelete from "./utils/ButtonsFunctions/handleDelete"
import handleInsert from "./utils/ButtonsFunctions/handleInsert"

export default function DetailTab({ models, field }) {
  const gridRef = useRef(null)

  const { ID, TABLENAME } = field

  const [modalVisible, setModalVisible] = useState(false)
  const [updateGrid, setUpdateGrid] = useState(false)
  const [updateForm, setUpdateForm] = useState(false)

  const [loadingFormConfiguration, setLoadingFormConfiguration] = useState(false)

  const props = {
    callback: null,
    closeModal: () => setModalVisible(false),
    entity: ID,
    detailTabPath: models._REGISTRATION_DATA[TABLENAME].detailTabsPath[ID],
    field,
    gridRef,
    modalVisible,
    models,
    screenType: "registrationDetail",
    setModalVisible,
    setUpdateForm,
    setUpdateGrid,
    updateForm,
    updateGrid,
    setLoadingFormConfiguration
  }

  const functions = {
    handleInsert: () => handleInsert(props),
    handleDelete: ({ changeLoadingModalVisibility }) => handleDelete({
      ...props,
      changeLoadingModalVisibility
    }),
    handleCopy: () => handleCopy(props)
  }

  return (
    <S.Container>
      <Grid
        {...props}
        functions={functions}
        rowDoubleClickCallback={() => setModalVisible(true)}
        screenType="registrationDetail"
      />

      {modalVisible && <FormModal {...props} />}

      <ModalWrapper isVisible={loadingFormConfiguration}>
        <S.LoadingModal>
          <LoadingAnimation size="large" color={defaultTheme["cyan-800"]} />
        </S.LoadingModal>
      </ModalWrapper>
    </S.Container>
  )
}
