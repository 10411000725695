import React from "react"
import * as S from "./styles"

export default function Decimal(props: any) {
  const value = props[props.fieldData?.ID]

  return (
    <S.Container>{value}</S.Container>
  )
}
