import { MaterialCommunityIcons } from "@expo/vector-icons"
import styled from "rn-css"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"

export const Container = styled.View`
  background-color: ${defaultTheme["gray-100"]};
  height: 60%;
  width: 50%;
  border-radius: 10px;
  gap: 15px;
  padding: 8px 12px;
`

export const CloseButton = styled.TouchableOpacity`
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`

export const Icon = styled(MaterialCommunityIcons)`
  color: ${defaultTheme["gray-900"]};
  font-size: 20px;
`

export const ListsView = styled.View`
  flex-direction: row;
  flex: 1;
  gap: 20px;
`

export const DividingLine = styled.View`
  background-color: ${defaultTheme["gray-400"]};
  height: 100%;
  width: 1px;
`
