import showAlert from "../../services/showAlert"

export default class HeaderController {

  constructor(params) {

    this.modelsTemplate = params.models()
    this.models = () => this.modelsTemplate
    this.OrderComposerController = params.orderComposerController
    this.saved = params.saved

    this.screenName = params.name
    this.collectionName = params.collectionName

    this.formFields = params.formFields
  }

  startChangeScreen = async (_, setIsLoading) => {
    if (await this.formValidation()) this.OrderComposerController.changeScreen({}, this, setIsLoading)
    else setIsLoading(s => !s)
  }

  async saveValues() { return null }

  async formValidation() {
    let isValid = false
    const fields = this.formFields

    let formattedFields = []
    fields.filter(field => field.T_DTD_ATR.REQUIRED)
      .map(field => formattedFields.push({
        FIELDNAME: field.ID,
        FIELDDESCRIPTION: field.T_DTD_ATR.FIELDDESCRIPTION
      }))

    if (formattedFields.length === 0) isValid = true

    let valid = []

    for (const field of formattedFields) {

      if (
        field.FIELDNAME in this.models().T_MOV_HDR === false ||
        this.models().T_MOV_HDR[field.FIELDNAME] === "" ||
        this.models().T_MOV_HDR[field.FIELDNAME] === undefined ||
        this.models().T_MOV_HDR[field.FIELDNAME] === null
      ) {
        showAlert({
          text: `${field.FIELDDESCRIPTION}`,
          title: "Required field not filled",
          titleType: "error"
        })
        break
      } else {
        valid.push(field.FIELDNAME)
        if (valid.length === formattedFields.length) isValid = true
      }
    }
    return isValid

  }
}
