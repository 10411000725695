import { EvilIcons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;

  span {
    color: ${defaultTheme["gray-900"]};
    font-size: 12px;
  }
`

interface AttachmentProps {
  hasValue: boolean
}

export const ImageContainer = styled(Container) <AttachmentProps>`
  background-color: ${({ hasValue }) => defaultTheme[hasValue ? "white" : "gray-200"]};
  border: 1px solid ${({ hasValue }) => defaultTheme[hasValue ? "cyan-500" : "gray-500"]};
  border-radius: 50%;
  height: 24px;
  overflow: hidden;
  min-width: 24px;
  max-width: 24px;
`

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const ImageIcon = styled(EvilIcons)
  .attrs(({
    color: defaultTheme["gray-500"],
    name: "image",
    size: 20
  }))``
