import { Platform } from "react-native"
import OrderComposerController from "../../../../controllers/OrderComposerController"
import OrderItemsController from "../../../../controllers/orderControllers/OrderItemsController"
import Navigation from "../../../../models/navigation"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../services/evaluateFunction"
import getTableFields from "../../../../services/registration/getTableFields"

export default async ({ record, navigation, orderItems = null, setIsLoading }) => {
  const controller = new OrderComposerController() as any

  await controller.initialize()

  controller.jumpScreenStatus = true

  await controller.goToScreen(record)

  if (orderItems) controller.models().T_MOV_ITE = orderItems

  controller.models().T_SET_TNS = await dbRequest.loadRecords("T_SET_TNS").where("ID", "==", record.TNSID.ID)
    .execute(r => r[0])

  const initialScreen = {
    models: controller.models,
    orderComposerController: controller,
    name: "Order Summary",
    collectionName: "T_MOV_ITE",
    //screenOrder: Object.keys(controller.orderPrototype).length + 1,
    ItemsHeaderFields: await getEntityFields("T_MOV_ITE"),
    transactionId: record.TNSID.ID,
    customFields: await getCustomFields(record.TNSID?.ID)
  } as any

  initialScreen.controller = new OrderItemsController(initialScreen)

  for (const field of controller.sideBarFields) {
    await executeTriggers(controller.models(), {
      ENTITY: field.T_DTD_ATR.ENTITY.ID,
      RECORD: record
    })
  }

  if (Platform.OS === "web") {
    controller.tabName = `Doc.: ${record.ID}`
    Navigation.createTab({
      component: "OrderComposer",
      params: {
        controller,
        initialScreen
      },
      tabName: `Doc.: ${record.ID}`
    })

    setIsLoading(false)

  } else {
    setTimeout(() => {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "Documento",
            params: {
              controller: controller,
              initialScreen: initialScreen
            }
          }
        ]
      })
    }, 500)
  }
}

async function getEntityFields(entity) {
  return await getTableFields({ entityName: entity })
}

const getTriggers = async () => {
  return await dbRequest
    .loadRecords("T_DTD_TAB/T_MOV_HDR/T_DTD_TRG")
    .execute((r: any) => r.sort((a, b) => a.ORDERBY - b.ORDERBY))
}

const executeTriggers = async (models, params) => {
  const triggers = await getTriggers() as any

  for (const trigger of triggers) {
    await evaluateFunction(trigger.EXPRESSION, models, params)
  }
}

async function getCustomFields(id) {
  let fields = await getTableFields({
    collectionPath: `T_MOV_ITE/T_SET_ITE/${id}`,
    entityName: "T_MOV_ITE"
  })

  if (!fields || fields.length === 0) {
    fields = await getTableFields({ entityName: "T_MOV_ITE" })
  }

  return fields.sort((a, b) => a?.T_DTD_ATR?.ORDERBY - b?.T_DTD_ATR?.ORDERBY)
}

