import { useState } from "react"
import { useTranslation } from "react-i18next"
import React, { ScrollView, View } from "react-native"
import globalStyles from "../../../../global/globalStyles"
import LoginData from "../../../../models/loginData"
import showAlert from "../../../../services/showAlert"
import ActionButton from "./ActionButton"
import Header from "./Header"
import MultiSelectionInput from "./MultiSelectInput"
import OrderStatus from "./OrderStatus"
import * as S from "./styles.web"
import Summary from "./Summary"
import handleCancelOrder from "./utils/handleCancelOrder"

const OrderSideBar = ({ screenProps, controller, handleReturn, handleConfirm, PLNstatus, jumpTo, navigation, setUpdate, setApplyFilters }) => {

  /* const { t } = useTranslation()

  const [searchText, setSearchText] = useState("")

  const showSendEmailButton = controller.models().T_SET_TNS.EMAILSENDID?.ID
    ? screenProps.collectionName === "T_MOV_ITE" && controller.models().T_SET_TNS.SENDTYPE?.VALUE !== "P"
    : false

  const showOpenReportButton = controller.models().T_SET_TNS.ORDERREPORT?.ID
    ? screenProps.collectionName === "T_MOV_ITE" && controller.models().T_SET_TNS.PRINTTYPE?.VALUE !== "N"
    : false

  const showSearchInput = screenProps.collectionName !== "T_MOV_ITE" && !screenProps.collectionName.includes("TAB_")

  const { permissions } = controller.models()._REGISTRATION_DATA.T_MOV_HDR

  const handleApplyFilters = () => {
    if ((!controller.searchFilters?.length || !controller?.searchFilters) && searchText !== "")
      return showAlert({
        text: "Select at least one field to search",
        title: "No filter selected",
        titleType: "error"
      })
    setApplyFilters(searchText ? true : false)
    setUpdate(s => !s)
  } */

  return (
    <S.Container>
      <OrderStatus models={controller.models()} />

      <S.DivisorLine />

      <Summary models={controller.models()} jumpTo={jumpTo} controller={controller} />

      {/*  <Header screenProps={screenProps} models={controller.models()} handleReturn={handleReturn} handleConfirm={handleConfirm} />

      <ActionButtonsView>
        {showSendEmailButton &&
          <ActionButton
            title="Send email"
            iconName="mail"
            handleFunction={() => {
              showAlert({
                type: "confirm",
                text: "Do you want to send the order as a PDF via email?",
                title: "PDF sending",
                handleConfirm: async () => {
                  controller.sendEmail()
                }
              })
            }} />}

        {showOpenReportButton &&
          <ActionButton
            title="Open report"
            iconName="pdffile1"
            handleFunction={() => {
              showAlert({
                type: "confirm",
                text: "Would you like to view the order report?",
                title: "View report",
                handleConfirm: async () => {
                  controller.openReport()
                }
              })
            }} />}
      </ActionButtonsView>

      {showSearchInput &&
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "center"
          }}
        >
          <MultiSelectionInput screenProps={screenProps} controller={controller} />
          <SearchInput
            placeholder={`${t("Research")}...`}
            placeholderTextColor={globalStyles.colors.placeholder}
            value={searchText}
            onChangeText={tx => {
              controller.searchText = tx
              setSearchText(tx)
            }}
            onSubmitEditing={handleApplyFilters}
          />
          <SearchButton onPress={handleApplyFilters}>
            <SeachIcon name="search" />
          </SearchButton>

        </View>
      }

      <ScrollView contentContainerStyle={{ paddingBottom: 10 }}>
        <OrderStatus models={controller.models()} />

        <DivisorLine />

        <Summary models={controller.models()} jumpTo={jumpTo} controller={controller} />
      </ScrollView>

      {screenProps.name !== "Transaction" && (permissions.CREATE || LoginData.getLoginData().ADMIN) &&
        <CancelButton onPress={() => handleCancelOrder(navigation, controller)}>
          <div title={t("Init a new order")}>
            <ButtonText>{t("New Order")}</ButtonText>
          </div>
        </CancelButton>}
 */}
    </S.Container>
  )
}

export default OrderSideBar

