import { GridProps } from ".."
import dbRequest from "../../../../services/dbRequest/dbRequest"
import evaluateFunction from "../../../../services/evaluateFunction"

interface Filter {
  field: string
  operator: string
  value: string | number | boolean
}

const getGridRecords = async ({ entity, models, detailTabPath }: GridProps) => {
  const tableData = models._REGISTRATION_DATA[entity]

  const tableFilters: Filter[] = await evaluateFunction(tableData.tableFilter ?? "return []", models)

  let request = dbRequest.loadRecords(detailTabPath || entity)

  for (const filter of tableFilters) {
    const { field, operator, value } = filter
    request = request.where(field, operator, value)
  }

  const records = await request.execute((response) => !response ? [] : response)
  //@ts-ignore
  const visibleRecords = records.filter(({ ID }) => Boolean(ID))

  models._REGISTRATION_DATA[entity].records = visibleRecords

  return visibleRecords
}

export default getGridRecords
