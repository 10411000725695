import styled from "styled-components"
import { defaultTheme } from "../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  width: 95%;
  height: max-content;
  gap: 15px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 14px;
  border: 1px solid ${defaultTheme["gray-400"]};
  background-color: ${defaultTheme["white"]};

  cursor: pointer;
`

export const FieldContent = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`

export const FieldTitle = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${defaultTheme["cyan-700"]};
  font-weight: lighter;
  font-size: 14px;
  margin-left: 5px;
  margin-bottom: 3px;
`

export const FieldValue = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  color: ${defaultTheme["gray-700"]};
  background-color: ${defaultTheme["gray-200"]};
  outline: 1px solid ${defaultTheme["gray-400"]};
  border-radius: 6px;
`

export const InfoButton = styled.button`
  position: absolute;
  width: 20px;
  height: 20px;
  top: -5px;
  right: -8px;
  border-radius: 50%;
  border: none;
  background-color: ${defaultTheme["cyan-700"]};

  color: ${defaultTheme["white"]};
  font-weight: 600;
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }
`
