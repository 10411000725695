import styled, { createGlobalStyle } from "styled-components"
import { defaultTheme } from "../../../../styles/themes/default"

export const ResetStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 8px;
`

export const Button = styled.button`
  display: flex;
  align-items: flex-end;
  padding: 4px 8px;
  gap: 5px;
  color: ${defaultTheme["gray-700"]};
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 5px;
  box-shadow: 0px 4px 8px ${defaultTheme["shadow"]};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;

  &:active {
    opacity: 0.8;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  min-height: 30%;
  max-height: 60%;
  background-color: ${defaultTheme["gray-200"]};
  overflow: hidden;
  border-radius: 5px;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  padding: 3px;

  background-color: ${defaultTheme["gray-100"]};

  border-radius: 5px;
  box-shadow: 2px 4px 8px ${defaultTheme["shadow"]};

  &:hover {
    transform: scale(1.05);
  }

  z-index: 2;
`

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  padding: 15px 0px 5px 15px;
  box-shadow: 0px 2px 4px ${defaultTheme["shadow"]};
  z-index: 1;
`

export const Body = styled.div`
  width: 100%;
  height: 100%;
`

export const ModalTitle = styled.h1`
  color: ${defaultTheme["gray-700"]};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;

  margin: 0px;
`

export const HeaderButtonsView = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
`

export const ButtonHeader = styled.button`
font-size: 0.875rem;
  font-weight: 400;
  padding: 3px 10px;
  background-color: ${defaultTheme["cyan-500"]};
  border-radius: 4px;
  box-shadow: 0 4px 6px ${defaultTheme["shadow"]}, 
              0 1px 3px ${defaultTheme["shadow"]};
  transition: box-shadow 0.3s ease-in-out;
  color: ${defaultTheme["white"]};
  margin-left: 10px;
`

export const Clear = styled(ButtonHeader)`
  background-color: ${defaultTheme["gray-500"]};
`

export const FormView = styled.div`
  overflow: scroll;
  width: calc(100% + 16px);
  height: calc(100% + 16px);

  background-color: ${defaultTheme["gray-200"]};
`
