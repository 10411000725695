import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import hasPermissionToUpdate from "../../../../services/hasPermissionToUpdate"
import { defaultTheme } from "../../../../styles/themes/default"
import * as S from "./styles"

export default function NavigationPathViewer({ navigationScreens = null, models, jumpTo, focusScreenName }) {

  const { t } = useTranslation()

  const canEditButton = (field) => {
    if (field?.ID === "TNSID") return false
    const canUpdate = hasPermissionToUpdate(models)
    return canUpdate
  }

  if (Object.keys(navigationScreens).length === 1) return (
    <S.Container style={{ marginTop: 20 }}>
      <S.Button style={{ cursor: "default" }}>
        <S.ButtonText style={{
          fontWeight: 600,
          color: defaultTheme["cyan-300"]
        }}>{t(navigationScreens[Object.keys(navigationScreens)[0]].name) + " >"} </S.ButtonText>
      </S.Button>
      <S.Separator>{"... >"}</S.Separator>
    </S.Container>
  )
  return (
    <S.Container>
      {Object.entries(navigationScreens).map(([key, value]) => {
        const isScreenSaved = value.controller.saved
        const isScreenFocused = focusScreenName === value.name
        const isScreenEdited = canEditButton(value?.HDRfield)

        return (
          <Fragment key={key}>
            {value.name !== "Transaction" && <S.Separator>{">"}</S.Separator>}
            <S.Button
              style={{ cursor: isScreenEdited && isScreenSaved ? "pointer" : "default" }}
              onClick={() => (isScreenEdited && isScreenSaved) && jumpTo(value.name)}
            >
              <S.ButtonText style={{
                fontWeight: isScreenSaved || isScreenFocused ? 600 : 400,
                color: isScreenFocused ? defaultTheme["cyan-300"] : defaultTheme["gray-500"]
              }}>{t(value.name)}</S.ButtonText>
            </S.Button>
          </ Fragment>
        )
      })}
    </S.Container>
  )
}
