import { useEffect, useState } from "react"
import evaluateFunction from "../../../../../../services/evaluateFunction"

export default function useInputManager({ models, field, updateForm, setUpdateForm }) {
  const { TABLENAME, ID, FIELDTYPE, T_DTD_ATR } = field

  const [state, setState] = useState(FIELDTYPE.VALUE === "B" ? false : "")

  const updateValues = (value: string) => {
    models[TABLENAME][ID] = value
    setState(value)
  }

  const onBlur = () => {
    models[TABLENAME][ID] = state
    setUpdateForm(s => !s)
  }

  useEffect(() => {
    if (T_DTD_ATR.EXPRESSION) {
      evaluateFunction(T_DTD_ATR.EXPRESSION, models)
        .then(r => {
          if (r) updateValues(r)
        })
      return
    }

    updateValues(models[TABLENAME][ID] ?? T_DTD_ATR.DEFAULTVALUE ?? (FIELDTYPE.VALUE === "B" ? false : ""))
  }, [updateForm])

  return {
    state,
    setState,
    onBlur,
    updateValues
  }
}
