import React from "react"
import * as S from "./styles"

export default function Boolean(props: any) {
  const value = props[props.fieldData?.ID] ?? false

  return (
    <S.Container>
      <S.Status status={value}>
        {String(value)}
      </S.Status>
    </S.Container>
  )
}
