import dbRequest from "../../../../services/dbRequest/dbRequest"
import runTriggers from "../../../../services/registration/runTriggers"
import showAlert from "../../../../services/showAlert"

export default async ({ controller, entity, setIsLoading, gridRef }) => {
  const selectedRecords = gridRef.current.getSelectedRecords()
  const hasSelectedRecords = selectedRecords.length > 0

  if (!hasSelectedRecords)
    return showAlert({
      text: "No record selected",
      title: "Failed to delete",
      titleType: "error"
    })

  showAlert({
    type: "confirm",
    text: "Do you want to delete the selected record(s)?",
    title: "This action cannot be undone",
    titleType: "warning",
    handleConfirm: async () => {
      //setIsLoading(true)
      // setPercentageLoading({
      //   isVisible: true,
      //   percentage: 0,
      //   stepTitle: "Deleting..."
      // })

      const models = controller.models()

      for (const record of selectedRecords) {
        models._REGISTRATION_DATA[entity].operation = "DEL"
        models._REGISTRATION_DATA[entity].state = "BEFORE"

        // setPercentageLoading(prev => ({
        //   ...prev,
        //   percentage: 12.5
        // }))

        const allTriggersValid = await runTriggers(models, entity)
        if (!allTriggersValid) {
          //   setPercentageLoading(prev => ({
          //     ...prev,
          //     stepTitle: "Error: Executing Triggers Failed"
          //   }))
          showAlert({
            text: "Trigger execution failed",
            title: "Failed to delete",
            titleType: "error"
          })

          setIsLoading(false)
          return
          //return setTimeout(() => {
          // setPercentageLoading({
          //   isVisible: false,
          //   percentage: 0,
          //   stepTitle: ""
          // })
          //}, 5000)
        }

        const entityPath = `T_MOV_HDR/${record.ID}/T_MOV_ITE`

        // setPercentageLoading(prev => ({
        //   ...prev,
        //   percentage: 25
        // }))

        const products = await dbRequest.loadRecords(entityPath).execute() as any

        // setPercentageLoading(prev => ({
        //   ...prev,
        //   percentage: 34
        // }))

        for (const product of products) {
          await dbRequest
            .deleteCollection(`${entityPath}/${product.ID}/T_MOV_TAX/`)
            .execute()

          await dbRequest
            .deleteRecords(`${entityPath}`)
            .record({ ...product })
            .execute()
        }

        // setPercentageLoading(prev => ({
        //   ...prev,
        //   percentage: 47
        // }))

        await dbRequest
          .deleteRecords("T_MOV_HDR")
          .record({ ...record })
          .execute()

        // setPercentageLoading(prev => ({
        //   ...prev,
        //   percentage: 54
        // }))

        models._REGISTRATION_DATA[entity].state = "AFTER"
        await runTriggers(models, entity)

        // setPercentageLoading(prev => ({
        //   ...prev,
        //   percentage: 76
        // }))

      }

      models[entity].reset()
      gridRef.current.deleteRecord()

      // setPercentageLoading({
      //   isVisible: true,
      //   stepTitle: "Record(s) Deleted!",
      //   percentage: 100
      // })

      showAlert({
        text: "Record(s) deleted",
        titleType: "success"
      })

      //setIsLoading(false)

      //setTimeout(() => {
      // setPercentageLoading({
      //   isVisible: false,
      //   stepTitle: "",
      //   percentage: 0
      // })
      //}, 2000)
    }
  })
}
