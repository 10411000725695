import { Feather, FontAwesome5, FontAwesome6, Fontisto, MaterialCommunityIcons, SimpleLineIcons } from "@expo/vector-icons"
import { defaultTheme } from "../../../../styles/themes/default"

export const toolbarIcons = {
  add: <FontAwesome6 name="add" size={14} color={defaultTheme["white"]} />,
  collapse: <MaterialCommunityIcons name="playlist-minus" size={20} color={defaultTheme["white"]} />,
  copy: <FontAwesome5 name="copy" size={14} color={defaultTheme["white"]} />,
  delete: <Feather name="trash-2" size={16} color={defaultTheme["white"]} />,
  expand: <MaterialCommunityIcons name="playlist-plus" size={20} color={defaultTheme["white"]} />,
  next: <FontAwesome6 name="arrow-right-long" size={14} color={defaultTheme["cyan-300"]} />,
  nextAlt: <FontAwesome6 name="arrow-right-long" size={14} color={defaultTheme["white"]} />,
  previous: <FontAwesome6 name="arrow-left-long" size={14} color={defaultTheme["cyan-300"]} />,
  previousAlt: <FontAwesome6 name="arrow-left-long" size={14} color={defaultTheme["white"]} />,
  refresh: <SimpleLineIcons name="refresh" size={14} color={defaultTheme["white"]} />,
  save: <Fontisto name="save" size={12} color={defaultTheme["white"]} />,
  undo: <MaterialCommunityIcons name="undo-variant" size={16} color={defaultTheme["white"]} />,
  refresh: <SimpleLineIcons name="refresh" size={16} color={defaultTheme["white"]} />
}
