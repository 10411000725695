import { MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons"
import styled from "rn-css"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.Text`
  font-size: 20px;
  color: ${defaultTheme["gray-900"]};
  font-weight: 500;
`

export const SearchContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SearchInput = styled.TextInput`
  background-color: ${defaultTheme["gray-100"]};
  color: ${defaultTheme["gray-900"]};

  width: 200px;
  padding: 0 4px;

  border-bottom: 1px solid ${defaultTheme["gray-400"]};
  outline-style: none;

  font-size: 14px;
`

export const Button = styled.TouchableOpacity``

export const SearchIcon = styled(FontAwesome)`
  color: ${defaultTheme["gray-900"]};
  font-size: 16px;
`

export const ButtonsView = styled.View`
  flex-direction: row;
  gap: 30px;
  align-items: center;
`

export const SaveButton = styled(Button)`
  background-color: ${defaultTheme["cyan-500"]};
  height: 28px;
  width: 75px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`

export const SaveText = styled.Text`
  font-size: 14px;
  color: ${defaultTheme["white"]};
`

export const CloseButton = styled(Button)`
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
`

export const Icon = styled(MaterialCommunityIcons)`
  color: ${defaultTheme["gray-900"]};
  font-size: 20px;
`

export const LoadingContainer = styled.View`
  align-items: center;
  align-self: center;
  background-color: ${defaultTheme["white"]};
  border-radius: 6px;
  color: ${defaultTheme["gray-900"]};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  gap: 10px;
  height: 16%;
  justify-content: center;
  width: 16%;
`
