import React from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../styles/themes/default"
import handleCloseTab from "../../utils/handleCloseTab"
import handleTabPress from "../../utils/handleTabPress"
import * as S from "./styles"

export default function Tab({ tabIndex, tabData, state, navigation, setSelectedTabName }) {

  const { t } = useTranslation()

  const isTabSelected = state.index === (tabIndex + 1)

  const params = {
    navigation,
    tabData,
    isTabSelected,
    state,
    setSelectedTabName
  }

  const onTabPress = () => {
    handleTabPress(params)
  }

  const onCloseTab = (event) => {
    event.stopPropagation()
    handleCloseTab(params)
  }

  return (
    <S.Container isTabSelected={isTabSelected} onClick={onTabPress} index={tabIndex} >
      <S.TextContainer isTabSelected={isTabSelected}>
        {t(tabData.name)}
      </S.TextContainer>

      <S.CloseButton onClick={onCloseTab}>
        <svg width="8" height="8" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
          <line x1="1" y1="1" x2="8" y2="8" stroke={defaultTheme["gray-500"]} strokeWidth="1.5" strokeLinecap="round" />
          <line x1="1" y1="8" x2="8" y2="1" stroke={defaultTheme["gray-500"]} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </S.CloseButton>
    </S.Container>
  )
}
