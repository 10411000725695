import { Entypo, EvilIcons } from "@expo/vector-icons"
import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

interface AttachmentProps {
  hasAttachment: boolean
}

export const ImageContainer = styled(Container) <AttachmentProps>`
  background-color: ${({ hasAttachment }) => defaultTheme[hasAttachment ? "white" : "gray-200"]};
  border: 1px solid ${({ hasAttachment }) => defaultTheme[hasAttachment ? "cyan-500" : "gray-500"]};
  border-radius: 50%;
  height: 24px;
  overflow: hidden;
  width: 24px;
`

export const Image = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`

export const ImageIcon = styled(EvilIcons)
  .attrs(({
    color: defaultTheme["gray-500"],
    name: "image",
    size: 20,
  }))``

export const ClipIcon = styled(Entypo)
  .attrs<AttachmentProps>(({ hasAttachment }) => ({
    color: defaultTheme[hasAttachment ? "green-700" : "gray-500"],
    name: "attachment",
    size: 16,
  }))``
