import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"
import { LoadingContainer } from "../../../../../../../../../../screens/OrderPanelScreen/styles.web"
import LoadingAnimation from "../../../../../../../../../LoadingAnimation/index.web"
import ModalWrapper from "../../../../../../../../../Modal"
import { SearchTextContext } from "../../contexts/SearchTextContext"
import * as S from "./styles"
import handleSave from "./utils/handleSave"

export default function Header({
  selectedOption,
  visibleFields,
  nonVisibleFields,
  closeModal,
  models
}) {
  const { t } = useTranslation()

  const { searchText, setSearchText } = useContext(SearchTextContext)

  const [isLoading, setIsLoading] = useState(false)

  const clearInput = () => {
    setSearchText("")
  }

  return (
    <S.Container>
      <S.Title>{t(selectedOption.DESCRIPTION)}</S.Title>
      <S.SearchContainer>
        <S.SearchInput
          value={searchText}
          onChangeText={setSearchText}
          selectTextOnFocus
          placeholder={`${t("Search")}`}
          placeholderTextColor={defaultTheme["gray-400"]}
          autoComplete="off"
        />

        <S.Button onPress={clearInput}>
          <S.SearchIcon name={searchText === "" ? "search" : "times"} />
        </S.Button>
      </S.SearchContainer>

      <S.ButtonsView>
        <S.SaveButton
          onPress={() => {
            handleSave({
              selectedOption,
              visibleFields,
              nonVisibleFields,
              closeModal,
              transactionID: models.T_SET_TNS.ID,
              setIsLoading
            })
          }}
        >
          {isLoading ? (
            <ModalWrapper isVisible={isLoading}>
              <LoadingContainer>
                <LoadingAnimation size="medium" color={defaultTheme["white"]} />
              </LoadingContainer>
            </ModalWrapper>
          ) : (
            <S.SaveText>{t("Save")}</S.SaveText>
          )}
        </S.SaveButton>

        <S.CloseButton onPress={closeModal}>
          <S.Icon name="close" />
        </S.CloseButton>
      </S.ButtonsView>
    </S.Container>
  )
}
