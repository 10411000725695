import styled from "rn-css"
import { defaultTheme } from "../../../../../../../../../../../styles/themes/default"

export const Container = styled.View`
  height: 100%;
  gap: 10px;
  flex: 1;
`

export const Header = styled.View`
  flex-direction: row;
  width: 94%;

  justify-content: space-between;
  align-items: center;
`

export const Row = styled.View`
  flex-direction: row;
  gap: 10px;
  margin-right: 30px;
`

export const Title = styled.Text`
  font-size: 14px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
`

export const List = styled.ScrollView`
  border: 1px dashed ${defaultTheme["gray-400"]};
  border-radius: 10px;
  padding: 8px;
`
