import models from "../../../../models"
import Permissions from "../../../../models/permissions"
import RuntimeConfiguration from "../../../../models/runtimeConfiguration"
import dbAccess from "../../../../services/dbRequest/dbAccess"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import handleLoginError from "../../../../services/handleLoginError"
import setTranslations from "../../../../services/setTranslations"
import showAlert from "../../../../services/showAlert"
import storeEmail from "./storeEmail"

interface LoginProps {
	email: string
	password: string
	navigate: (screenName: string) => void
}

export default async function handleLogin({ email, password, navigate }: LoginProps) {
	try {
		await dbAccess.logIn(email, password)

		const permissionsPromise = Permissions.initialize()
		const storeEmailPromise = storeEmail(email, password)
		const translationsPromise = setTranslations()

		const promisesArray = [permissionsPromise, storeEmailPromise, translationsPromise]

		await Promise.all(promisesArray)

		await models.prepareClone()

		navigate("Root")
	} catch (e) {
		console.error(e)
		const errorMessage = handleLoginError(e.code)
		showAlert({
			text: errorMessage,
			titleType: "error"
		})
	}
}
