import { Platform } from "react-native"
import PriceTableData from "../../models/priceTableData"
import dbRequest from "../../services/dbRequest/dbRequest"
import _id from "../../services/dbRequest/requests/getDocumentId"
import evaluateFunction from "../../services/evaluateFunction"
import removeFieldsFromObject from "../../services/removeFieldsFromObject"
import showAlert from "../../services/showAlert"

export default class ProductsController {

  constructor(params) {

    this.OrderComposerController = params.orderComposerController

    this.modelsTemplate = params.models()
    this.models = () => this.modelsTemplate

    this.saved = params.saved
    this.screenName = params.name
    this.collectionName = params.collectionName
    this.ItemsHeaderFields = params.ItemsHeaderFields
    this.customFields = params.customFields

    this.MOVITEAttributes = null

    this.itemsHeaderStructure = () => {
      const fields = params.ItemsHeaderFields
      this.MOVITEAttributes = fields
      const formatedObj = {}
      fields.map(field => {
        formatedObj[field.ID] = field?.T_DTD_ATR?.DEFAULTVALUE ?? null
      })

      return formatedObj
    }

    this.taxes = this.getTAX()
    this.promotions = this.getPromotions()
  }

  listEntityName = () => "T_REC_ITE"

  async getRecords(lastDocument, setLastDocument, recordsLength, searchText, applyFilters) {

    const filtersArray = await this.getFilters()

    let itemsId = await PriceTableData.getPRICETABLEDATA()

    if (typeof itemsId !== "object" || itemsId === null) {
      showAlert({
        type: "ok",
        text: "Incorrectly configured pricing",
        title: "Pricing error",
        titleType: "error",
        handleConfirm: async () => {
          this.models().T_MOV_HDR.reset()
          this.models().T_MOV_ITE = []

          this.OrderComposerController.initialize()
          this.OrderComposerController.jumpScreenStatus = false
          this.OrderComposerController.setScreens([])

          this.navigation.navigate("Transaction")

        },
        handleReject: () => null
      })

      return []
    }

    if (filtersArray.length > 0) {
      for (const filter of filtersArray) {
        const { field, operator, value } = filter
        request = request.where(field, operator, value)
      }
    }

    let request = dbRequest.loadRecords(this.listEntityName())

    const maxItems = Platform.OS === "web" ? 30 : 10

    if (!applyFilters) {

      itemsId = Object.keys(itemsId).sort((a, b) => a.localeCompare(b))

      if (lastDocument) {
        const lastIdIndex = itemsId.findIndex(element => element === lastDocument.id)

        if (lastIdIndex >= 0) {
          const startSliceIndex = lastIdIndex + 1
          const endSliceIndex = startSliceIndex + maxItems
          const slicedIds = itemsId.slice(startSliceIndex, endSliceIndex)

          if (slicedIds.length > 0) request = request.where(_id, "in", slicedIds)
          else return []

        } else return []
      } else if (recordsLength === 0) {
        request = request.where(_id, "in", itemsId.slice(0, maxItems))
      } else return []

      const records = await request
        .lastDocument(lastDocument)
        .hasLimit()
        .execute(({ data, lastDocument }) => {
          setLastDocument(lastDocument)
          return data
        })

      this.recordsList = records

      return records
    } else {

      let filteredRecords = []

      for (const field of this.searchFilters) {
        await request
          .search({
            field: field,
            searchText: searchText
          })
          .execute(async (r) => {
            await r.map((record) => {
              if (record.ID in itemsId) filteredRecords.push(record)
            })
          })
      }

      const uniqueArray = filteredRecords.filter((obj, index, self) => index === self.findIndex((o) => o.ID === obj.ID))

      return uniqueArray
    }
  }

  async startChangeScreen(action, setIsLoading, navigation) {

    if (action === "cancel") {
      if (this.models().T_MOV_HDR.ID) {
        showAlert({
          type: "confirm",
          text: "Do you want to cancel editing?",
          title: "Edition cancellation",
          titleType: "warning",
          handleConfirm: () => navigation.navigate("Order Summary"),
          handleReject: () => null
        })
      } else {
        showAlert({
          type: "confirm",
          text: "Do you want to cancel the order and start a new one?",
          title: "Order cancellation",
          titleType: "warning",
          handleConfirm: async () => {
            this.models().T_MOV_HDR.reset()
            this.models().T_MOV_ITE = []

            this.OrderComposerController.initialize()
            this.OrderComposerController.jumpScreenStatus = false
            this.OrderComposerController.setScreens([])

            navigation.navigate("Transaction")
          },
          handleReject: () => null
        })
      }
    } else return this.OrderComposerController.changeScreen({ toSave: true }, this, setIsLoading)
  }

  async saveValues(props) {

    if (!props?.toSave) return

    if (this.models().T_MOV_HDR.ID) {

      await dbRequest
        .updateRecords("T_MOV_HDR")
        .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
        .execute()

      for (let record of this.models().T_MOV_ITE) {

        let newRecord = removeFieldsFromObject({ ...record })

        await dbRequest
          .saveRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
          .record({ ...newRecord })
          .execute(() => {
            this.saveTaxSubCollection(newRecord)
          })

        record = newRecord
      }
    } else {

      await dbRequest
        .saveRecords("T_MOV_HDR")
        .record(removeFieldsFromObject({ ...this.models().T_MOV_HDR }))
        .execute(r => { this.models().T_MOV_HDR.ID = r.ID })

      for (let record of this.models().T_MOV_ITE) {
        let newRecord = removeFieldsFromObject({ ...record })
        await dbRequest
          .saveRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE`)
          .record({ ...newRecord })
          .execute(async () => {
            await this.saveTaxSubCollection(newRecord)
          })

        record = newRecord
      }
    }
  }

  async saveTaxSubCollection(record) {
    await dbRequest
      .deleteCollection(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE/${record.ID}/T_MOV_TAX`)
      .execute()

    for (const tax in this.models().T_MOV_TAX) {
      const newObj = removeFieldsFromObject({ ...this.models().T_MOV_TAX[tax].T_MOV_TAX_ITEMS })
      newObj.ID = tax

      await dbRequest
        .saveRecords(`T_MOV_HDR/${this.models().T_MOV_HDR.ID}/T_MOV_ITE/${record.ID}/T_MOV_TAX`)
        .record({ ...newObj })
        .execute()
    }
  }

  getPromotions = async () => {
    const T_SET_PRM = await dbRequest
      .loadRecords("T_SET_PAR")
      .where("ID", "==", "T_REC_PRM")
      .execute(async (r) => {
        if (!r) return []
        return await evaluateFunction(r[0].VALUE)
      })

    if (!T_SET_PRM) return []
    for (const promotion of T_SET_PRM) {
      promotion.T_SET_IPR = await dbRequest
        .loadRecords(`T_SET_PRM/${promotion.ID}/T_SET_IPR`)
        .execute()

      for (const item of promotion.T_SET_IPR) {
        item.T_SET_RPR = await dbRequest
          .loadRecords(`T_SET_PRM/${promotion.ID}/T_SET_IPR/${item.ID}/T_SET_RPR`)
          .execute(r => r.sort((a, b) => Number(b.ID) - Number(a.ID)))
      }
    }
    this.T_SET_PRM = T_SET_PRM
    return T_SET_PRM
  }

  async getTAX() {
    const taxesArray = await dbRequest
      .loadRecords("T_SET_TAX")
      .execute(r => r.sort((a, b) => a?.ORDERBY - b?.ORDERBY))

    for (const tax of taxesArray) {
      tax.T_SET_TAX_ITEMS = await dbRequest.loadRecords(`T_SET_TAX/${tax.ID}/T_SET_TAX_ITEMS`).execute()
    }

    return taxesArray
  }

  async getTaxTemplate() {
    const taxes = await this.taxes

    const taxesTemplate = {}

    for (const tax of taxes) {
      taxesTemplate[tax.ID] = {}
      taxesTemplate[tax.ID].T_MOV_TAX_ITEMS = {}
      for (const field of tax.T_SET_TAX_ITEMS) {
        taxesTemplate[tax.ID].T_MOV_TAX_ITEMS[field.ID] = null
      }
    }
    return taxesTemplate
  }

  async getProductsInPromotion() {
    let values = null
    if (this.T_SET_PRM.length > 0 && this.T_SET_PRM[0].VALUE === 0) {
      values = this.T_SET_PRM[0].T_SET_IPR?.map(item => item.ID)
      return values
    } else return []
  }

  async getProductUnits(productID) {
    return await dbRequest
      .loadRecords(`T_REC_ITE/${productID}/T_REC_IUN`)
      .execute()
  }

  async getFilters() {
    return await dbRequest
      .loadRecords("T_DTD_TAB/T_MOV_ITE/T_DTD_FLD/ITEMID/T_DTD_ATR")
      .execute(r => {
        const filter = r.find((item) => item.ID === "FILTER")
        return evaluateFunction(filter?.VALUE ?? "return []")
      })
  }
}

