import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

interface ButtonProps {
  variant?: "white" | null
  disabled?: boolean
}

export const Container = styled.div<ButtonProps>`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  justify-content: center;
  padding: 3px 10px 3px 3px;
  pointer-events: ${({ disabled }) => disabled ? "none" : "all"};
  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }

  span {
    color: ${defaultTheme["gray-500"]};
    font-size: 0.875rem;
  }
`

export const Button = styled.button<ButtonProps>`
  align-items: center;
  background-color: ${({ variant, disabled }) => {
    if (disabled) return defaultTheme["gray-300"]
    return defaultTheme[variant === "white" ? "white" : "cyan-300"]
  }};
  border-radius: 50%;  
  display: flex;
  height: 26px;
  justify-content: center;
  width: 26px;

  pointer-events: ${({ disabled }) => disabled ? "none" : "all"};

  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }
`
