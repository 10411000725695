import { enableRipple } from "@syncfusion/ej2-base"
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons"
import React, { useEffect, useState } from "react"
import showAlert from "../../../../../services/showAlert"
import DynamicModal from "./components/DynamicModal/index"
import ModalContent from "./components/ModalContent"
import * as S from "./styles"
import getOptions from "./utils/getOptions"
import handleExport from "./utils/handleExport"

enableRipple(true)
export default function MoreOptionsButton({ entity, models, gridRef, setUpdateForm, mode = "grid" }) {
  const [options, setOptions] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleSelect = (args) => {
    if (args.item.EXPRESSION === "fnc_exportSpreadsheet")
      return handleExport({
        entity,
        gridRef
      })

    if (args.item.SELECTLINE) {
      const selectedRecords = gridRef.current?.getSelectedRecords() || models[entity]

      if (selectedRecords.length === 0)
        return showAlert({
          text: "A record must be selected",
          title: "Failed to execute function",
          titleType: "warning"
        })

      if (selectedRecords.length > 1)
        return showAlert({
          text: "Multiple records must selected",
          title: "Failed to execute function",
          titleType: "warning"
        })

      models[entity].populate({ ...selectedRecords[0] })
    }

    if (args.item.EXPRESSION === "fnc_access" && models[entity].ADMIN)
      return showAlert({
        text: "It is not possible to change an admin's access",
        title: "Access denied",
        titleType: "warning"
      })

    setSelectedOption(args.item)
    setModalVisible(true)
  }

  useEffect(() => {
    getOptions({
      entity,
      models,
      mode
    }).then(setOptions)
  }, [])

  return (
    <div>
      <style>{S.css}</style>

      <DropDownButtonComponent
        id="more-options"
        items={options}
        cssClass="e-caret-hide"
        select={handleSelect}
      >
        <S.Container>
          <S.IconContainer>
            <S.Icon name="ellipsis-h" />
          </S.IconContainer>
        </S.Container>
      </DropDownButtonComponent>

      {modalVisible && (
        <DynamicModal isVisible={modalVisible}>
          <ModalContent
            entity={entity}
            models={models}
            closeModal={() => setModalVisible(false)}
            selectedOption={selectedOption}
            gridRef={gridRef}
            setUpdateForm={setUpdateForm}
          />
        </DynamicModal>
      )}
    </div>
  )
}

