import dbRequest from "../services/dbRequest/dbRequest"

export default class globalDate {

  constructor(dateString) {
    this.date = dateString ? new Date(dateString) : new Date()
  }

  getDay(date = this.date) { return date.getDate() }
  getMonth(date = this.date) { return date.getMonth() + 1 }
  getFullYear(date = this.date) { return date.getFullYear() }

  getHours(date = this.date) { return date.getHours() }
  getMinutes(date = this.date) { return date.getMinutes() }
  getSeconds(date = this.date) { return date.getSeconds() }

  toLocal(date = this.date) { return date.toLocaleDateString("pt-BR") }// DD/MM/AAAA

  toISO(date = this.date) { // AAAA-MM-DD HH:MM:SS
    const day = this.padNumber(date.getDate())
    const month = this.padNumber(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = this.padNumber(date.getHours())
    const minutes = this.padNumber(date.getMinutes())
    const seconds = this.padNumber(date.getSeconds())

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }

  toTimestamp(date = this.date) { // DD/MM/AAAA HH:MM:SS
    const day = this.padNumber(date.getDate())
    const month = this.padNumber(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = this.padNumber(date.getHours())
    const minutes = this.padNumber(date.getMinutes())
    const seconds = this.padNumber(date.getSeconds())

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }

  padNumber(number) {
    return number.toString().padStart(2, "0")
  }

  formatDateToStr(date = new Date()) {
    let d = date.getDate()
    let m = date.getMonth() + 1
    let y = date.getFullYear()
    return `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d} 00:00:00`
  }

  formatTimestampToStr(date = new Date()) {
    let d = date.getDate()
    let m = date.getMonth() + 1
    let y = date.getFullYear()
    let h = date.getHours()
    let min = date.getMinutes()
    let s = date.getSeconds()
    return `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d} ${h < 10 ? "0" + h : h}:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`
  }

  getStrToDate(dateStr) {
    let [datePart, timePart] = dateStr.split(/[T ]/)
    let [year, month, day] = datePart.split("-").map(Number)

    return new Date(year, month - 1, day)
  }

  getStrToTimestamp(dateStr) {
    let [datePart, timePart] = dateStr.split(/[T ]/)
    let [year, month, day] = datePart.split("-").map(Number)
    let [hours, minutes, seconds] = timePart.split(":").map(Number)

    return new Date(year, month - 1, day, hours, minutes, seconds)
  }

  //Função que retorna os dias em um determinado periodo de tempo, sendo possível considerar ou desconsiderar sábado, domingo e feriados, onde:
  //     period_start -> String no formato "2024-12-31 00:00:00" 
  //     period_end -> String no formato "2024-12-31 00:00:00"
  //     saturday -> Boelan sendo true para contar como dia útil e false para subtrair
  //     sunday -> Boelan sendo true para contar como dia útil e false para subtrair
  //     holidays -> Boelan sendo true para considerar cadastro de feriados e false para desconsiderar os feriados na análise
  getWorkingDays(startDate, endDate, includeSaturdays = false, includeSundays = false, considerHolidays = false) {
    const startPeriod = new Date(startDate);
    const endPeriod = new Date(endDate);
    let currentDate = new Date(startPeriod);
    const workingDays = [];
    const holidayDates = new Map();

    const addHolidayDatesToMap = (holiday, startYear, endYear) => {
        const [month, day] = holiday.ID.split("-");
        for (let year = startYear; year <= endYear; year++) {
            const holidayDate = new Date(year, month - 1, day);
            holidayDates.set(this.formatDateToStr(holidayDate), holiday);
        }
    };

    const processHolidayRecords = (holidayRecords) => {
        const startYear = startPeriod.getFullYear();
        const endYear = endPeriod.getFullYear();

        holidayRecords.forEach(holiday => {
            if (holiday.YEAR === 1970) {
                addHolidayDatesToMap(holiday, startYear, endYear);
            } else {
                const holidayDate = new Date(holiday.YEAR, ...holiday.ID.split("-").map((v, i) => i === 0 ? v - 1 : v));
                holidayDates.set(this.formatDateToStr(holidayDate), holiday);
            }
        });
    };

    // Ajuste para tratar possível retorno vazio ou nulo
    const holidayRecords = dbRequest.loadRecords("T_REC_HLD").execute();

    if (considerHolidays) {
        if (Array.isArray(holidayRecords) && holidayRecords.length > 0) {
            // Se holidayRecords for uma array e contiver elementos, processa-os
            processHolidayRecords(holidayRecords);
        } else if (holidayRecords?.items && Array.isArray(holidayRecords.items) && holidayRecords.items.length > 0) {
            // Se holidayRecords tiver uma propriedade `items` que é uma array com elementos, processa-os
            processHolidayRecords(holidayRecords.items);
        } else {
            // Não há feriados ou o formato é inesperado
            console.warn("Nenhum feriado encontrado ou os registros de feriado não estão no formato esperado.");
        }
    }

    while (currentDate <= endPeriod) {
        const dayOfWeek = currentDate.getDay();
        const currentDateStr = this.formatDateToStr(currentDate);

        const isWeekend = (dayOfWeek === 0 && !includeSundays) || (dayOfWeek === 6 && !includeSaturdays);
        const isHoliday = holidayDates.has(currentDateStr);

        if (!isWeekend && !isHoliday) {
            workingDays.push({ WORKINGDAYS: currentDateStr });
        }

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return workingDays;
}


  getMinutesToHours(minutes) { // Retorna no formato HH:mm
    const hours = String(Math.floor(minutes / 60)).padStart(2, "0")
    const remainingMinutes = String(minutes % 60).padStart(2, "0")

    return `${hours}:${remainingMinutes}`
  }

  getHoursToMinutes(hours) {
    const [hh, mm] = hours.split(":").map(Number)
    return hh * 60 + mm
  }

}
