import React, { useState } from "react"
import appJson from "../../../../app.json"
import LoginForm from "./components/LoginForm"
import RecoverPasswordModal from "./components/Modal"
import * as S from "./styles.web"

export default function LoginScreen({ navigation }) {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <S.LoginScreenContainer>
      <S.FormContainer>
        <S.Title>Bem Vindo</S.Title>
        <S.Text>Insira suas crendenciais para acessar sua conta</S.Text>

        <LoginForm navigation={navigation} />

        <S.RecoverPasswordText>
          Esqueceu sua senha? <button onClick={() => setModalVisible(true)}>Recuperar senha</button>
        </S.RecoverPasswordText>

        <S.InfoText href="https://www.musatech.com.br/" target="_blank">
          Desenvolvido por Doal - musatech.com.br
        </S.InfoText>
        <S.AppVersion>v. {appJson.expo.version}</S.AppVersion>

        <S.LogoContainer>
          <S.LogoImage
            src={require("../../images/logo-musa-login.png")}
            alt="Musa Logo"
          />
        </S.LogoContainer>
      </S.FormContainer>
      <S.ImageCarousel />

      <RecoverPasswordModal isVisible={modalVisible} closeModal={() => setModalVisible(false)} />
    </S.LoginScreenContainer>
  )
}
