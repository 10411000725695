import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import de from "./de.json"
import en from "./en.json"
import es from "./es.json"
import fr from "./fr.json"
import ja from "./ja.json"
import pt from "./pt.json"

const getStoredLanguage = async (languageKey) => {
  try {
    const storedLanguage = await AsyncStorage.getItem(languageKey)
    return storedLanguage ? JSON.parse(storedLanguage) : null
  } catch (error) {
    console.error("Error reading AsyncStorage:", error)
    return null
  }
}

const initializeI18n = async () => {
  const storedEnglish = await getStoredLanguage("en")
  const storedPortuguese = await getStoredLanguage("pt")
  const storedSpanish = await getStoredLanguage("es")
  const storedGerman = await getStoredLanguage("de")
  const storedFrench = await getStoredLanguage("fr")
  const storedJapanese = await getStoredLanguage("ja")

  const resources = {
    en: storedEnglish || en,
    pt: storedPortuguese || pt,
    es: storedSpanish || es,
    de: storedGerman || de,
    fr: storedFrench || fr,
    ja: storedJapanese || ja
  }

  i18n
    .use(initReactI18next)
    .init({
      compatibilityJSON: "v3",
      lng: "pt",
      resources,
      react: { useSuspense: false },
      interpolation: { escapeValue: false }
    })
}

initializeI18n()

export default i18n
