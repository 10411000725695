import styled from "styled-components"
import { defaultTheme } from "../../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

interface StatusProps {
  status: boolean
}

export const Status = styled.div<StatusProps>`
  background-color: ${({ status }) => defaultTheme[status ? "green-100" : "red-100"]};
  border: 1px solid ${({ status }) => defaultTheme[status ? "green-700" : "red-900"]};
  border-radius: 6px;
  color: ${({ status }) => defaultTheme[status ? "green-700" : "red-900"]};
  font-weight: 500;
  text-align: center;
  width: 54px;
`
