import applyFilters from "./applyFilters"
import applySearchText from "./applySearchText"
import createFieldsObject from "./createFieldsObject"
import createFiltersArray from "./createFiltersArray"

export default ({ tableData, models, viewType, searchText, entityName }) => {
  const {
    fields = [],
    isHierarchic = false,
    records = []
  } = tableData

  let filteredRecords = records

  const filters = createFiltersArray({
    models,
    viewType,
    entityName
  })

  const hasFiltersToApply = filters.length > 0

  const fieldsObject = createFieldsObject({ fields })

  if (hasFiltersToApply)
    filteredRecords = applyFilters(entityName, filteredRecords, filters, fieldsObject, models, isHierarchic)

  const hasSearchedValue = searchText !== ""
  if (hasSearchedValue)
    filteredRecords = applySearchText(filteredRecords, fieldsObject, searchText, isHierarchic)

  return {
    filteredRecords
  }
}
