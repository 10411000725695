import { MaterialIcons } from "@expo/vector-icons"
import styled from "rn-css"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.View`
  height: 30px;
  justify-content: center;
  margin: 10px 0px;
`

export const ReturnButton = styled.TouchableOpacity`
  width: 30px;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  
  &:hover {
    background-color: ${globalStyles.colors.tertiary};
    border-radius: 5px;
    box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  }
`

export const ButtonIcon = styled(MaterialIcons)`
  color: ${globalStyles.colors.tertiary};
  &:hover {
    color: ${globalStyles.colors.editButton};
  }
`

export const ScreenTitle = styled.Text`
  color: ${globalStyles.colors.textTitle};
  font-size: 20px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  max-width: 80%;
`

export const ConfirmButton = styled.TouchableOpacity`
  position: absolute;
  right: 6px;
  height: 100%;
  justify-content: center;
  padding: 0px 6px;
  background-color: ${globalStyles.colors.secondary};
  border-radius: 3px;
`

export const ButtonText = styled.Text`
  font-size: ${globalStyles.sizes.fontSize}px;
  color: ${globalStyles.colors.tertiary};
  font-weight: 600;
`

export const CancelButton = styled.TouchableOpacity`
  position: absolute;
  left: 6px;
  height: 100%;
  justify-content: center;
  padding: 0px 6px;
  border-radius: 3px;  
  background-color: ${globalStyles.colors.cancelButton};
`

