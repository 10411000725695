import { GridComponent, RowDataBoundEventArgs } from "@syncfusion/ej2-react-grids"

interface RowClickProps {
  args: RowDataBoundEventArgs
  grid: GridComponent
  entity: string
  models: any
  records: object[]
  detailTabs: object[]
  screenType: "registrationMaster" | "registrationDetail" | "orderPanel" | "searchModal"
  rowDoubleClickCallback: (record?: object) => void
}

const handleRowPress = ({ args, grid, entity, models, records, detailTabs, screenType, rowDoubleClickCallback }: RowClickProps) => {
  if (args.row) {
    args.row.addEventListener("click", (e) => {
      const { rowData } = grid.getRowInfo(e.target) as { rowData: { ID: string } }
      const tableData = models._REGISTRATION_DATA[entity]

      const filteredRecords = grid.getFilteredRecords()

      const tableRecords = filteredRecords.length > 0 ? filteredRecords : models._REGISTRATION_DATA[entity].records

      const selectedRecordIndex = tableRecords.findIndex((record: any) => record.ID == rowData.ALTID)

      tableData.currentRecordIndex = selectedRecordIndex

      const selectedRecord = filteredRecords.length > 0
        ? models._REGISTRATION_DATA[entity].records.find(record => record.ID === tableRecords[selectedRecordIndex].ALTID) || {}
        : tableRecords[selectedRecordIndex]

      if (screenType === "searchModal")
        return rowDoubleClickCallback(selectedRecord)

      models[entity].reset()
      models[entity].populate({ ...selectedRecord })
      tableData.originalRecord = { ...selectedRecord }

      tableData.operation = "UPD"

      if (screenType !== "orderPanel") {
        for (const table of detailTabs) {
          //@ts-ignore
          const tableID = table.ID
          const recordID = selectedRecord.ID

          if (screenType === "registrationMaster")
            tableData.detailTabsPath[tableID] = `${entity}/${recordID}/${tableID}`
          else
            tableData.detailTabsPath[tableID] = `${models._REGISTRATION_DATA[tableData.masterTable].detailTabsPath[entity]}/${recordID}/${tableID}`
        }
      }
    })

    args.row.addEventListener("dblclick", (e) => {
      if (screenType !== "searchModal")
        rowDoubleClickCallback()
    })
  }
}

export default handleRowPress
