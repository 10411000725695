import React from "react"
import * as S from "./styles"

export default function Currency(props: any) {
  const value = props[props.fieldData?.ID] ?? 0

  return (
    <S.Container>
      <span>R$</span>

      <span>{value}</span>
    </S.Container>
  )
}
