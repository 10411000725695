import Attachment from "../DynamicField/Attachment"
import Boolean from "../DynamicField/Boolean"
import Currency from "../DynamicField/Currency"
import Date from "../DynamicField/Date"
import Datetime from "../DynamicField/Datetime"
import Decimal from "../DynamicField/Decimal"
import Hour from "../DynamicField/Hour"
import Integer from "../DynamicField/Integer"
import MultiSelect from "../DynamicField/MultiSelect"
import Search from "../DynamicField/Search"
import Selection from "../DynamicField/Selection"
import Text from "../DynamicField/Text"

export default function getFieldToRender({ fieldData }) {
  const Fields = {
    A: Attachment, // Attachment
    B: Boolean, // Boolean
    C: Currency, // Currency
    D: Date, // Date
    F: Decimal, // Float
    H: Hour, // Hour
    I: Integer, // Integer
    K: Search, // Key (Foreign Key)
    L: Selection, // List
    M: Text, // Multiline
    N: Search, // Navigation
    S: Text, // String
    T: Datetime, // Timestamp
    O: MultiSelect // MultiSelectedField
  }

  return Fields[fieldData.FIELDTYPE.VALUE] ?? Boolean
}
