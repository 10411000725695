import evaluateFunction from "../evaluateFunction"
import showAlert from "../showAlert"
import getTriggers from "./getTriggers"

export default async (models, entityName) => {

  const triggers = await getTriggers(entityName) ?? []

  const activeTrigegrs = triggers.filter(({ ACTIVE }) => ACTIVE === true)

  const orderedTrigegrs = activeTrigegrs.sort((a, b) => a.ORDERBY - b.ORDERBY)

  for (const trigger of orderedTrigegrs) {
    if (!await evaluateFunction(trigger.EXPRESSION, models)) {
      showAlert({
        title: "Trigger failed",
        titleType: "error",
        text: `Trigger ID: ${trigger.ID}`
      })
      return false
    }
  }

  return true
}
