import styled from "styled-components"
import { defaultTheme } from "../../../../../../../styles/themes/default"

export const Container = styled.div`
  position: relative;
  height: 100%;
  z-index: 999;
`

export const FilterButton = styled.button`
  height: 100%;
  width: 36px;
  border-radius: 10px 0 0 10px;
  border: .8px solid ${defaultTheme["gray-500"]};
  border-right-width: 0px;
  background-color: ${({ isDisabledSearchInput }) => isDisabledSearchInput ? defaultTheme["gray-100"] : defaultTheme["white"]};

  color: ${defaultTheme["gray-700"]};
  font-size: 14px;

  cursor: pointer;

  &:active{
    background-color: ${defaultTheme["gray-100"]};
  }
`

export const DropdownList = styled.ul`
  position: absolute;
  top: 29px;
  left: 0px;
  width: 200px;
  list-style: none;
  margin: 0;
  padding: 4px 10px 10px 10px;
  overflow: scroll;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0px")};
  transition: max-height .3s ease-in-out, opacity .3s ease-in-out; 
  border-radius: 14px;
  border: 1px solid ${defaultTheme["gray-600"]};
  background-color: ${defaultTheme["white"]};
`

export const DropdownTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${defaultTheme["gray-700"]};
`

export const DropdownListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
`

export const DropdownListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: ${defaultTheme["cyan-700"]};
  font-weight: lighter;
`

export const Checkbox = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ isSelected }) => isSelected ? defaultTheme["cyan-300"] : defaultTheme["white"]};
  border: ${({ isSelected }) => isSelected ? "none" : ` 1px solid ${defaultTheme["gray-700"]}`};

  transition: background-color .3s ease-in-out, border .3s ease-in-out;
  cursor: pointer;
`

export const FiltersCounter = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${defaultTheme["cyan-700"]};
  font-size: 10px;
  font-weight: 500;
  color: ${defaultTheme["white"]};
`
