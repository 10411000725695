import { populateProducts } from "../../../../redux/redux-reducers/order-reducer"
import dbRequest from "../../../../services/dbRequest/dbRequest"
import showAlert from "../../../../services/showAlert"
import navigateToITE from "./navigateToITE"

export default async ({ controller, dispatch, setIsLoading, gridRef, entity }) => {
  const selectedRecords = gridRef.current.getSelectedRecords()

  if (selectedRecords.length === 0)
    return showAlert({
      text: "No record selected",
      title: "Failed to duplicate",
      titleType: "error"
    })

  if (selectedRecords.length > 1)
    return showAlert({
      text: "Only one record can be duplicated at a time",
      title: "Failed to duplicate",
      titleType: "error"
    })

  const record = controller.models()._REGISTRATION_DATA[entity].formattedRecords[selectedRecords[0].ID]

  showAlert({
    type: "confirm",
    text: "Do you want to duplicate this record?",
    title: "Record duplication",
    handleConfirm: async () => {
      // setPercentageLoading({
      //   isVisible: true,
      //   stepTitle: "Copying record...",
      //   percentage: 0
      // })

      setIsLoading(true)

      const orderItems = await controller.getItemsOrders(record.ID)
      delete record.ID

      // setPercentageLoading(prev => ({
      //   ...prev,
      //   percentage: 13
      // }))

      record.STATUS = {
        LABEL: "In Attendance",
        VALUE: "A"
      }

      const ID = await dbRequest
        .saveRecords("T_MOV_HDR")
        .record({ ...record })
        .execute((r: any) => r.ID)

      // setPercentageLoading(prev => ({
      //   ...prev,
      //   percentage: 26
      // }))

      const itemsPromises = orderItems.map((item) => (
        dbRequest
          .saveRecords(`T_MOV_HDR/${ID}/T_MOV_ITE`)
          .record({ ...item })
          .execute()
      ))

      // setPercentageLoading(prev => ({
      //   ...prev,
      //   percentage: 42
      // }))

      await Promise.all(itemsPromises)

      // setPercentageLoading(prev => ({
      //   ...prev,
      //   percentage: 69
      // }))

      record.ID = ID
      dispatch(populateProducts(orderItems))

      // setPercentageLoading(prev => ({
      //   ...prev,
      //   percentage: 84
      // }))

      await navigateToITE({ record, navigation: controller, orderItems, setIsLoading })
    }
  })
}
