import { MultiSelect } from "react-native-element-dropdown"
import styled from "styled-components/native"
import globalStyles from "../../../../../global/globalStyles"

export const Container = styled.View`
  background-color: ${globalStyles.colors.inputsBackground};
  
  width: 80px;

  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right-width: 1px;
  border-right-color: ${globalStyles.colors.gridRowColor};
`

export const CounterView = styled.View`
  width: 16px;
  height: 16px;

  position: absolute;
  left: -5;
  top: -5;
  z-index: 10;

  justify-content: center;
  align-items: center;

  border-radius: 8px;

  background-color: ${globalStyles.colors.cancelButton};
`

export const CounterText = styled.Text`
  color: ${globalStyles.colors.tertiary};
  font-size: 10px;
`

export const MultiSelectionInput = styled(MultiSelect)`
  width: 80px;
  height: 35.5px;
  border-radius: 3px;
  padding: 2px;

  font-size: ${globalStyles.sizes.fontSize}px;

  background-color: ${globalStyles.colors.tertiary};
`
