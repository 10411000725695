import React from "react"
import { useTranslation } from "react-i18next"
import useAppSelector from "../../../../../redux/hooks/useAppSelector"
import * as S from "./styles"
import formatNumber from "./utils/formatNumber"

export default function OrderStatus({ models }) {
  const { t } = useTranslation()

  const totalizerValues = useAppSelector(({ orderReducer }) => orderReducer.totalizerValues)

  return (
    <S.Container>
      <S.Title>{t("Order Information")}</S.Title>

      <S.Content>
        <S.FieldView>
          <S.Key>ID</S.Key>
          <S.Value title={models.T_MOV_HDR.ID ?? "--"}>{models.T_MOV_HDR.ID ?? "--"}</S.Value>
        </S.FieldView>

        <S.FieldView>
          <S.Key>{t("Status")}</S.Key>
          <S.Value>{t(models.T_MOV_HDR.STATUS.LABEL)}</S.Value>
        </S.FieldView>

        <S.FieldView>
          <S.Key>{t("Qt. Products")}: </S.Key>
          <S.Value>{totalizerValues.totalQuantity ?? 0}</S.Value>
        </S.FieldView>

        <S.FieldView>
          <S.Key>{t("Weight")}: </S.Key>
          <S.Value>{totalizerValues.totalWeight?.toFixed(3) ?? 0}</S.Value>
        </S.FieldView>

        <S.FieldView>
          <S.Key>{t("Shipping cost")}:</S.Key>
          <S.Value>R$ {formatNumber(models.T_MOV_HDR.SHIPPINGVALUE ?? 0)}</S.Value>
        </S.FieldView>

        <S.FieldView>
          <S.Key>{t("Total")}:</S.Key>
          <S.Value>R$ {formatNumber(totalizerValues.totalAmount ?? 0)}</S.Value>
        </S.FieldView>
      </S.Content>

    </S.Container>
  )
}
