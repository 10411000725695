import styled from "styled-components"
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  background-color: ${defaultTheme["white"]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 32px;
  padding: 0 10px;
`

export const Title = styled.span`
  color: ${defaultTheme["gray-500"]};
  font-size: 0.875rem;  
  margin-right: 10px;
`

export const Input = styled.select`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  font-size: 0.875rem;
  border: none;
  width: 160px;
  height: ${defaultTheme.formInput.height}px;
  outline: none;
  
  ::placeholder {
    color: ${defaultTheme["gray-500"]};
    font-size: 0.875rem;
  }

`

export const Label = styled.option``

export const ActionButton = styled.button`
  align-items: center;
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 50%;  
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;
  margin-left: 10px;

  transition: opacity 0.2s;

  &:active {
    opacity: 0.3;
  }  
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
  width: 350px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${defaultTheme["white"]};
`

export const ModalTitle = styled.span`
  color: ${defaultTheme["gray-700"]};
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InputTitle = styled.p`
  color: ${defaultTheme["cyan-800"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  font-weight: bold;
  line-height: 20px;
  text-align: start;
  &::after {
    content: "*";
    color: ${defaultTheme["cyan-800"]};
    display: inline;
  }
`

export const InputDescription = styled.input`
  background-color: ${defaultTheme["white"]};
  color: ${defaultTheme["gray-700"]};
  font-size: ${defaultTheme.formInput.fontSize}px;
  width: 90%;
  border: 1px solid ${defaultTheme["gray-300"]};
  border-radius: 10px;
  padding: 0 10px;
  height: 34px;
  outline: none;

  ::placeholder {
    color: ${defaultTheme["gray-300"]};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`

export const Button = styled.button`
  background-color: ${defaultTheme["cyan-700"]};
  border-radius: 10px;
  color: ${defaultTheme["white"]};
  font-size: 0.875rem;
  font-weight: 500;
  height: 30px;
  width: 100px;
`
