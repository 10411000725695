import styled from "styled-components"
import { FontAwesome } from '@expo/vector-icons'
import { defaultTheme } from "../../../../../styles/themes/default"

export const Container = styled.div`
  align-items: center;
  background-color: ${defaultTheme["gray-100"]};
  border-radius: 10px;
  display: flex;
  gap: 6px;
  justify-content: center;
  height: 32px;
  width: 36px;
`

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${defaultTheme["cyan-300"]};
  border-radius: 50%;  
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
`

export const Icon = styled(FontAwesome)
  .attrs(({
    name: "ellipsis-h",
    color: defaultTheme["white"],
    size: 14
  }))``

export const css = `
  #more-options {
    padding: 0;
    border-radius: 8px;
    background-color: transparent;
  }

  .e-dropdown-btn.e-active {
    background-color: ${defaultTheme["white"]};
  }

  .e-dropdown-popup {
    margin-top: 5px;
    right: 20px;
    left: auto !important;
    border-radius: 4px;
  }

  .e-menu-icon .e-cart-icon .e-link {
    color: black;
  }

  .e-dropdown-popup ul {
    border-radius: inherit;
    padding: 0 4px;
  }

  .e-dropdown-popup ul .e-item {
    border-bottom: 1px solid lightgrey;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    margin: 0px 4px 8px;
    padding: 0 4px;
    height: 34px;

    &:hover {
      scale: 1.05;
      border-bottom-color: ${defaultTheme["gray-900"]};
      background-color: transparent;
    }
  }
`
