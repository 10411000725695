import React from "react"
import { useTranslation } from "react-i18next"
import AccessibilityBox from "../AccessibilityBox"
import useHours from "../hooks/useHours"
import * as S from "./styles.web"

export default function Hours({ models, field, updateForm, setUpdateForm }) {
  const { t } = useTranslation()

  const { EDITABLE, REQUIRED, FIELDDESCRIPTION, HELPTEXT } = field.T_DTD_ATR

  const { state, setState, onBlur } = useHours({
    models,
    field,
    updateForm,
    setUpdateForm
  })

  return (
    <S.Container>
      <S.Row>
        <S.Title isRequired={REQUIRED}>{t(FIELDDESCRIPTION)}</S.Title>

        <AccessibilityBox text={HELPTEXT} />
      </S.Row>

      <S.Input
        value={state}
        type="datetime"
        placeholder="00:00"
        isEditable={EDITABLE}
        onBlur={onBlur}
        onChangeText={setState}
      />
    </S.Container>
  )
}
