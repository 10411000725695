import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import OrderPanelController from "../../../controllers/OrderPanelController"
import LoadingModal from "../../components/LoadingModal"
import * as S from "./styles.web"
import copyRecord from "./utils/copyRecord"
import deleteRecord from "./utils/deleteRecord"
import insertRecord from "./utils/insertRecord"
import switchMode from "./utils/switchMode"
import Grid from "../../components/Grid"
import { useTranslation } from "react-i18next"
import ModalWrapper from "../../components/Modal"
import LoadingAnimation from "../../components/LoadingAnimation/index.web"

export default function OrderPanelScreen() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const gridRef = useRef(null)

  const [controller] = useState(new OrderPanelController())

  // const [percentageLoading, setPercentageLoading] = useState({
  //   percentage: 0,
  //   stepTitle: "",
  //   isVisible: false
  // })

  const [isLoading, setIsLoading] = useState(false)

  const props = {
    controller,
    entity: "T_MOV_HDR",
    dispatch,
    isLoading,
    setIsLoading,
    gridRef
  }

  const functions = {
    handleInsert: () => insertRecord(props),
    handleDelete: () => deleteRecord(props),
    handleCopy: () => copyRecord(props)
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isCtrlPressed = event.ctrlKey
      const isKeyI = event.key === "i" || event.key === "I"
      const isKeyD = event.key === "d" || event.key === "D"
      const isKeyR = event.key === "r" || event.key === "R"


      if (isCtrlPressed && (isKeyI || isKeyD || isKeyR)) {
        event.preventDefault()
        if (isKeyI) insertRecord(props)
        if (isKeyD) deleteRecord(props)
        if (isKeyR) gridRef.current.refresh() //setUpdateTable(s => !s)
      }
      else {
        if (event.key === "Insert") insertRecord(props)
        if (event.key === "Delete") deleteRecord(props)
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <S.Container>
      <Grid
        entity="T_MOV_HDR"
        functions={functions}
        gridRef={gridRef}
        models={controller.models()}
        screenType="orderPanel"
        rowDoubleClickCallback={() => switchMode(props)}
      />

      {isLoading && (
        <ModalWrapper isVisible={isLoading}>
          <S.LoadingContainer>
            <LoadingAnimation size="large" color="#2E2E2E" />

            {t("Loading")}...
          </S.LoadingContainer>
        </ModalWrapper>
      )}

      {/* <LoadingModal
        isVisible={percentageLoading.isVisible}
        size={60}
        strokeWidth={6}
        percentageSettings={percentageLoading}
        onCloseModal={() => setPercentageLoading(s => ({
          ...s,
          isVisible: false
        }))}
      /> */}
    </S.Container>
  )
}
