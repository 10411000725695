import React from "react"
import globalDate from "../../../../../../global/globalDate"
import * as S from "./styles"

export default function Date(props: any) {
  const value = props[props.fieldData?.ID]
  const formattedDate = value ? new globalDate(value).toLocal() : ""

  return (
    <S.Container>{formattedDate}</S.Container>
  )
}
