import React, { useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { defaultTheme } from "../../../../../../../../../styles/themes/default"
import { LoadingContainer } from "../../../../../../../../screens/OrderPanelScreen/styles.web"
import LoadingAnimation from "../../../../../../../LoadingAnimation"
import ModalWrapper from "../../../../../../../Modal"
import Header from "./components/Header"
import NonVisibleFieldsList from "./components/NonVisibleFieldsList"
import VisibleFieldsList from "./components/VisibleFieldsList"
import { SearchTextProvider } from "./contexts/SearchTextContext"
import * as S from "./styles"
import getFields from "./utils/getFields"

export default function ConfigurationModal({
  models,
  selectedOption,
  closeModal
}) {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const [visibleFields, setVisibleFields] = useState([])
  const [nonVisibleFields, setNonVisibleFields] = useState([])
  const [updateList, setUpdateList] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getFields({
      configurationPath: selectedOption.EXPRESSION,
      transactionID: models.T_SET_TNS.ID,
      t
    }).then(({ visible, nonVisible }) => {
      setNonVisibleFields(nonVisible)
      setVisibleFields(visible)
      setIsLoading(false)
    })
  }, [updateList])

  const props = {
    models,
    selectedOption,
    closeModal,
    visibleFields,
    setVisibleFields,
    nonVisibleFields,
    setNonVisibleFields,
    setUpdateList
  }

  return (
    <S.Container>
      <SearchTextProvider>
        <DndProvider backend={HTML5Backend}>
          <Header {...props} />
          {isLoading ? (
            <ModalWrapper isVisible={isLoading}>
              <LoadingContainer>
                <LoadingAnimation size="medium" color={defaultTheme["white"]} />
              </LoadingContainer>
            </ModalWrapper>
          ) : (
            <S.ListsView>
              <NonVisibleFieldsList {...props} />

              <S.DividingLine />

              <VisibleFieldsList {...props} />
            </S.ListsView>
          )}
        </DndProvider>
      </SearchTextProvider>
    </S.Container>
  )
}
